export const ADD_REQUEST_DATA = 'ADD_REQUEST_DATA';
export const CLEAR_REQUEST_DATA = 'CLEAR_REQUEST_DATA';
export const POPULATE_REQUEST_DATA = 'POPULATE_REQUEST_DATA';
export const CLEAR_DATE_AND_TIME = 'CLEAR_DATE_AND_TIME';

export const addDataAction = (payload) => ({
  type: ADD_REQUEST_DATA,
  payload,
});
export const clearDataAction = () => ({
  type: CLEAR_REQUEST_DATA,
});
export const clearDateAndTime = () => ({
  type: CLEAR_DATE_AND_TIME,
});
export const populateDataAction = (payload) => ({
  type: POPULATE_REQUEST_DATA,
  payload
});
