import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { confirmSendModalAction, requestModalAction, solicitationSendedModalAction } from '../../redux/actions/modalsActions';
import axiosBasf from '../../axiosConfig';
import { generateBodyForm } from '../../utils/eventFormUtils'
import { Divider } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const confirmSendIsOpen = useSelector((reducer) => reducer.modalsReducer.confirmSendIsOpen);
  const formData = useSelector((reducer) => reducer.requestReducer)
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(confirmSendModalAction(false));
    dispatch(requestModalAction(true));
  }
  
  const handleSendClick = () => {
    dispatch(confirmSendModalAction(false));
    axiosBasf.post('/events/save', generateBodyForm(formData, 1), { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(confirmSendModalAction(false));
        dispatch(solicitationSendedModalAction(true));
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={confirmSendIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBackClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" size="large" sx={{ textAlign: 'center', fontSize: '25px', }}>Atenção!</DialogTitle>
        <Divider variant="middle"/>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center'}}>
            Tem certeza que deseja enviar esta solicitação para aprovação da Administração?
            Após o envio não será possível editá-la.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleSendClick } variant="contained">Enviar</Button>
          <Button onClick={ handleBackClick } variant="contained" color="error">Voltar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}