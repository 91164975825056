import { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FestivalOutlinedIcon from '@mui/icons-material/FestivalOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import { colors } from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import { supportAction } from '../redux/actions/modalsActions';
import drawerLogo from '../img/drawer_logo.png'
import {
  Box,
  Typography,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';



export default function SideBar() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((reducer) => reducer.authReducer.user)
  const SideBar = styled(Box)({
    // [theme.breakpoints.down('md')]: { display: 'none'},
    backgroundColor: colors.green,
    width: '230px',
    height: '100%',
  })

  return (
    <SideBar>
        {/* <img src={drawerLogo} alt="basf logo" style={{ height: '62px', margin: '30px'}}/> */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant="h6" size="large" sx={{ color: 'white', margin: '30px'}}>Plataforma<br/>Administrativa<br/>de Eventos</Typography>
        </Box> 
        <List sx={{ width: '100%'}} component="nav">
          <Divider sx={{ opacity: '0.6'}} light />
          <Link to="/home">
            <ListItemButton sx={{ paddingLeft: '30px'}}>
              <ListItemIcon sx={{ color: 'white'}}>
                <HomeOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Home" sx={{ position: 'relative', color: 'white', left: '-20px' }}/>
            </ListItemButton>
          </Link>
          <Divider sx={{ opacity: '0.6'}} light />
          { userData.permission !== 1
          ? null
          : <Link to="/users">
            <ListItemButton sx={{ paddingLeft: '30px' }}>
              <ListItemIcon sx={{ color: 'white' }}>
                <PeopleAltOutlinedIcon fontSize="small" />
              </ListItemIcon >
              <ListItemText primary="Usuários" sx={{ position: 'relative', color: 'white', left: '-20px' }} />
            </ListItemButton>
          </Link>}
          <Divider sx={{ opacity: '0.6'}} light />
          <ListItemButton sx={{ paddingLeft: '30px'}} onClick={() => setOpen(!open)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <FestivalOutlinedIcon fontSize="small"/>
            </ListItemIcon >
            <ListItemText primary="Eventos" sx={{ position: 'relative', color: 'white', left: '-20px' }}/>
            { open ? <ExpandLess sx={{ color: 'white'}}/> : <ExpandMore sx={{ color: 'white'}}/>}
          </ListItemButton>
          <Divider sx={{ opacity: '0.6'}} light />
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/solicitations">
                  <ListItemButton sx={{ pl: 4 }} >
                    <ListItemText primary="Solicitações" disableTypography sx={{ position: 'relative', color: 'white',  pl: 4, fontWeight: 200}}/>
                  </ListItemButton>
                </Link>
                <Link to="/events">
                  <ListItemButton sx={{ pl: 4}}>
                    <ListItemText primary="Eventos Aprovados"disableTypography sx={{ position: 'relative', color: 'white',  pl: 4, fontWeight: 200}}/>
                  </ListItemButton>
                </Link>
              </List>
            <Divider sx={{ opacity: '0.6'}} light />
            </Collapse>
          <ListItemButton sx={{ paddingLeft: '30px'}}>
            <ListItemIcon sx={{ color: 'white' }}>
              <HelpOutlineOutlinedIcon fontSize="small"/>
            </ListItemIcon >
            <ListItemText primary="Ajuda/Suporte" sx={{ position: 'relative', color: 'white', left: '-20px' }} onClick={() => dispatch(supportAction(true))}/>
          </ListItemButton>
          <Divider sx={{ opacity: '0.6'}} light />
        </List>
    </SideBar>
  )
}