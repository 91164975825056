import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSolicitationsAction } from '../redux/actions/eventsActions';
import { useSelector } from 'react-redux';
import axiosBasf from '../axiosConfig';

function formatDate(date, time) {
  if (!date || !time) return null;
  const formatedDate = date.slice(0, 10);
  const eventTime = time.slice(10);
  return `${formatedDate}` + eventTime
}

export default function CalendarHolder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const solicitations = useSelector((reducer) => reducer.eventsReducer.solicitations);
  const [ eventsList, setEventsList] = useState([]);

  

  const getSolicitationColor = (state) => {
    if(state === 'Aguardando aprovação') return '#ffc107';
    if(state === 'Aprovada') return '#008247';
    if(state === 'Reprovada') return '#ff1744';
  }

  useEffect(() => {
    fetchSolicitationsAction();
    axiosBasf.get('/events/list', { headers: {authorization: localStorage.getItem('token')}})
    .then((response) => {
      setEventsList(
        response.data.filter(({ state }) => state !== 'Reprovada')
        .map(({id, name, date_event, start_time, state}) => ({
          id,
          title: name,
          date: formatDate(date_event, start_time),
          backgroundColor: getSolicitationColor(state),
        }))
      )
      // setEventsList(response.data.map(({id, name, date_event, start_time, state}) => ({
      //   id,
      //   title: name,
      //   date: formatDate(date_event, start_time),
      //   backgroundColor: getSolicitationColor(state),
      // })))
    })
  }, [solicitations])

  
  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto'}}>
      <Paper sx={{ padding: '30px'}}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          // plugins={['interaction'}}
          buttonText={{ today: 'Hoje', day: 'Dia', month: 'Mês', year: 'Ano', week: 'Semana', allDay: 'Dia' }}
          locale={'pt-BR'}
          themeSystem={'standard'}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          // expandRows={true}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          // height={800}
          contentHeight={780}
          aspectRatio={1.75}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: false
          }}
          events={eventsList}
          allDaySlot={false}
          // eventMouseEnter={(e) => {
          //   if(e.view.type !== 'dayGridMonth') return;
          //   console.log('ok')
          // }}


        // weekends={this.state.weekendsVisible}
        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        // select={this.handleDateSelect}
        // eventContent={renderEventContent} // custom render function
        // eventClick={this.handleEventClick}
        // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}

        */
        />
      </Paper>
    </Box>
  )
}

