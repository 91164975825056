import { ADD_REQUEST_DATA, POPULATE_REQUEST_DATA, CLEAR_REQUEST_DATA, CLEAR_DATE_AND_TIME } from '../actions/requestActions';

const INITIAL_STATE = {
  id: 0,
  name: '',
  responsibleEmail: '',
  requesterEmail: '',
  occupationArea: '',
  local: '',
  date: null,
  startTime: '',
  endTime: '',
  eventType: '1',
  typeTransmission: [],
  typeInteractions: [1],
  chat: '1',
  quiz: '1',
  audience: '',
  publicProfile: '',
  cultive: '',
  objective: '',
  // results: '',
  numUsers: '',
  schedule: '0',
  observation: '',
}

const requestReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch(type) {
  case ADD_REQUEST_DATA:
    return {...state, [payload.field]: payload.data}
  case POPULATE_REQUEST_DATA:
    return {...payload}
  case CLEAR_REQUEST_DATA:
    return {...INITIAL_STATE}
  case CLEAR_DATE_AND_TIME:
    return {...state, date: null, startTime: '', endTime: ''}
  default:
    return state;
  }
}

export default requestReducer;