import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { savedModalAction, supportAction } from '../../redux/actions/modalsActions';
import { Divider, Typography } from '@mui/material';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Box } from '@mui/system';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Support() {
  const supportIsOpen = useSelector((reducer) => reducer.modalsReducer.supportIsOpen);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(supportAction(false));
  }
  
  return (
    <div>
      <Dialog
        open={supportIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClick}
        aria-describedby="alert-dialog-slide-description"
        scroll="body"
      >
        <Box sx={{ display: 'flex', flexDirection: 'Column', alignItems: 'center'}}>
          <ContactSupportIcon color="primary" sx={{ fontSize: '50px', marginTop: '15px'}}/>
          <DialogTitle color="primary" sx={{ textAlign: 'center', fontSize: '25px'}}>Entre em contato conosco</DialogTitle>
        </Box>

        <Divider variant="middle"/>
        <DialogContent sx={{ textAlign: 'center'}}>
            <Typography color="primary" sx={{ fontWeight: '800', fontSize:'20px'}}>Glaciane Romanini</Typography>
            <Typography>+55 11 2039-3339</Typography>
            <Typography>+55 11 9 6383-0095</Typography>
            <Typography>glaciane.romanini@basf.com</Typography>
            <Divider variant="middle" sx={{margin: '20px 0'}}/>
            <Typography color="primary" sx={{ fontWeight: '800', fontSize:'20px'}}>Renata Dias</Typography>
            <Typography>+55 (11) 99981-4036</Typography>
            <Typography>renata.dias@pixit.com.br</Typography>
            <Divider variant="middle" sx={{margin: '20px 0'}}/>
            <Typography color="primary" sx={{ fontWeight: '800', fontSize:'20px'}}>Priscila Fernandes</Typography>
            <Typography>+55 (11)96118-4467</Typography>
            <Typography>priscila.fernandes@pixit.com.br</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleClick } variant="contained">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}