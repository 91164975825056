import React from "react";
import PDF from "../assets/pdf/tabela-transmissoes.pdf";

export default function PDFViewer() {

	return (
		<div
		style={{height: '100vh', width: '100vw', position: 'relative'}}
		>
			<embed
				onContextMenu={(e) => e.preventDefault()}
				id="fraDisabled"
				src={PDF + "#toolbar=0"}
				type="application/pdf"
				height='100%'
				width='100%'
			/>
		</div>
	)
};