export const ADD_USER_DATA = 'ADD_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const POPULATE_USER_DATA = 'POPULATE_USER_DATA';

export const addDataAction = (payload) => ({
  type: ADD_USER_DATA,
  payload,
});
export const clearDataAction = () => ({
  type: CLEAR_USER_DATA,
});
export const populateDataAction = (payload) => ( {
  type: POPULATE_USER_DATA,
  payload
});
