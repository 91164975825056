import xlsx from 'json-as-xlsx';

export const subscribersToExcel = (subscribers) => {
  const content = [
    {
      sheet: 'Inscritos e participantes',
      columns: [
        { label: 'Nome', value: 'nome' },
        { label: 'Email', value: 'email' },
        { label: 'Estado', value: 'UF' },
        { label: 'Cidade', value: 'city' },
        { label: 'Função', value: 'role' },
        { label: 'Atuação', value: 'acting' },
        { label: 'Cultura', value: 'culture' },
        { label: 'Inscrição', value: 'subscribed_at' },
        { label: 'Participação', value: 'first_participation' },
      ],
      content: subscribers,
    }
  ];
  const settings = {
    fileName: 'Inscritos e participantes', // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  }
  return xlsx(content, settings);
}
export const interactionsToExcel = (interactions) => {
  const content = [
    {
      sheet: 'Interações',
      columns: [
        { label: 'Nome', value: 'name' },
        { label: 'Email', value: 'email' },
        { label: 'Mensagem', value: 'message' },
      ],
      content: interactions,
    }
  ];
  const settings = {
    fileName: 'Interações', // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  }
  return xlsx(content, settings);
}

