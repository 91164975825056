import {  useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import jwt from 'jwt-decode';
import { loginAction } from './redux/actions/authActions';


export default function PrivateRoute() {
  const dispatch = useDispatch();
  const location = useLocation();

  const token = localStorage.getItem('token');
  if(!token) return  <LoginPage state={{ from: location }}/>
  const userData = jwt(token).data;
  dispatch(loginAction(userData));
  return <Outlet />
}