import { ADD_USER_DATA, CLEAR_USER_DATA, POPULATE_USER_DATA } from '../actions/userModalActions';

const INITIAL_STATE = {
  id_user: 0,
  id_nivel_access: '',
  name: '',
  pass: '',
  email: '',
}


const userReducerModal = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ADD_USER_DATA:
      return { ...state, [payload.field]: payload.data }
    case POPULATE_USER_DATA:
      return { ...payload }
    case CLEAR_USER_DATA:
      return { ...INITIAL_STATE }
    default:
      return state;
  }
}

export default userReducerModal;
// export const NAME = 'NAME';
// export const RESPONSIBLE = 'RESPON';
// export const OCCUPATION_AREA = 'OCCUPATION_AREA';
// export const LOCAL = 'LOCAL';
// export const DATE = 'DATE';
// export const START_TIME = 'START_TIME';
// export const END_TIME = 'END_TIME';
// export const EVENT_TYPE = 'EVENT_TYPE'
// export const TYPE_TRANSMISSION = 'TYPE_TRANSMISSION';
// export const CHAT = 'CHAT';
// export const QUIZ = 'QUIZ';
// export const AUDCIENCE = 'AUDCIENCE';
// export const CULTIVE = 'CULTIVE';
// export const OBJECTIVE = 'OBJECTIVE';
// export const RESULTS = 'RESULTS';
// export const NUM_USERS = 'NUM_USERS';
// export const SCHEDULE = 'SCHEDULE';