import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from 'moment';
import axiosInstance from '../axiosConfig';
import DashboardSubscribersTable from '../components/DashboardSubscribersTable';
import DashboardInteractionsTable from '../components/DashboardInteractionsTable';
import {interactionsToExcel, subscribersToExcel} from '../utils/generateExcel';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Dashboard() {
  const { id } = useParams();

  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [convertingSubscribers, setConvertingSubscribers] = useState(false);
  const [convertingInterations, setConvertingInterations] = useState(false);

  const fetchData = useCallback(() => {
    axiosInstance.get(`/dashboard/${id}`, { headers: { authorization: localStorage.getItem('token') } })
      .then(({ data }) => {
        console.log(data);
        setDashboardData(data);
      })
      .catch((err) => setDashboardData(null))
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  const formatAverageTime = (seconds) => {
    return moment('2022-01-01T00:00:00').add(seconds, 'seconds').format('HH:mm:ss')
  }

  const handleLoadingButton = (field) => {
    if (field === 'subscribers') {
      setConvertingSubscribers(true);
      subscribersToExcel(dashboardData.participantsSubscribers)
      setConvertingSubscribers(false)
      return
    }
    setConvertingInterations(true);
    interactionsToExcel(dashboardData.interactions)
    setConvertingInterations(false)
  }

  if (loading) {
    return (
      <div>Carregando dados...</div>
    )
  }

  if (!dashboardData) {
    return (
      <div>Sem dados no momento!</div>
    )
  }

  return (
    <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ padding: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
              {/* <Typography color="primary" variant="h6">{dashboardData.local}</Typography> */}
              <Box sx={{display: 'flex'}}>
                
                <Typography variant='h6' color="primary">{dashboardData.name}</Typography>
              </Box>
              <Box sx={{display: 'flex'}}>
                <Typography color="primary" sx={{fontWeight: '400', marginRight: '5px'}}>Data e hora do evento:</Typography>
                <Typography>{`${moment(dashboardData.date_event).format('DD/MM/YYYY')}, das ${moment(dashboardData.start_time).format('HH:mm')} às ${moment(dashboardData.end_time).format('HH:mm')}`}</Typography>
              </Box>
              <Box sx={{display: 'flex'}}>
                <Typography color="primary" sx={{fontWeight: '400', marginRight: '5px'}}>E-mail do solicitante:</Typography>
                <Typography>{dashboardData.requesterEmail}</Typography>
              </Box>
              <Box sx={{display: 'flex'}}>
              <Typography color="primary" sx={{fontWeight: '400', marginRight: '5px'}}>Solicitação:</Typography>
                <Typography>{dashboardData.id}</Typography>
              </Box>
            </Box>
             <Box>
               <Typography color="primary" sx={{fontWeight: '400', marginRight: '5px'}}>Última alteração:</Typography>
               <Typography>{moment(dashboardData.updated_at).format('[no dia] DD/MM/YYYY [às] HH:mm')}</Typography>
             </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Typography color="primary" variant="h4"sx={{fontWeight: '800'}}>{dashboardData.totalSubscribers}</Typography>
            <Typography>Inscritos</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Typography color="primary" variant="h4"sx={{fontWeight: '800'}}>{dashboardData.totalParticipants}</Typography>
            <Typography>Participantes</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Typography color="primary" variant="h4"sx={{fontWeight: '800'}}>{dashboardData.totalInteractions}</Typography>
            <Typography>Interações</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Typography color="primary" variant="h4"sx={{fontWeight: '800'}}>{formatAverageTime(dashboardData.averageTime)}</Typography>
            <Typography>Tempo Médio</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h5" sx={{fontWeight: 800}}>Inscritos e participantes</Typography>
              <LoadingButton variant="contained" loading={convertingSubscribers} loadingIndicator="Gerando Excel" onClick={() => handleLoadingButton('subscribers')}>Exportar Excel</LoadingButton>
            </Box>
            { dashboardData.participantsSubscribers.length ? (
              <DashboardSubscribersTable subscribers={dashboardData.participantsSubscribers} />
            ) : (
            <Box sx={{ minHeight: '150px'}}>
              <Typography variant="h5" sx={{fontWeight: 800}}>Sem inscritos / participantes.</Typography>
            </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper align="center" sx={{padding: '15px'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="h5" sx={{ fontWeight: 800}}>Interações</Typography>
              <LoadingButton variant="contained" loading={convertingInterations} loadingIndicator="Carregando..." onClick={() => handleLoadingButton('interations')}>Exportar Excel</LoadingButton>
            </Box>
            { dashboardData.interactions.length ? (
              <DashboardInteractionsTable interactions={dashboardData.interactions} />
            ) : (
            <Box sx={{ minHeight: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h5" sx={{fontWeight: 800}}>Sem interações.</Typography>
            </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}