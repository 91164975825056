import {
  REQUEST_MODAL,
  CONFIRM_SEND_MODAL,
  EXTENDED_CONFIRM_SEND_MODAL,
  SOLICITATION_SENDED_MODAL,
  SAVED_MODAL,
  CANCEL_MODAL,
  REMOVE_MODAL,
  FREEZE_REQUEST,
  OVERVIEW_MODAL,
  USER_FORM_MODAL,
  APPROVE_MODAL,
  REPROVE_MODAL,
  DELETE_USER_MODAL,
  SCHEDULE_MODAL,
  EMAIL_MODAL,
  SUPPORT_MODAL,
  RESET_MODAL,
  LOADING,

} from "../actions/modalsActions";

const INITIAL_STATE = {
  requestIsOpen: false,
  confirmSendIsOpen: false,
  extendedSendIsOpen: false,
  sendedIsOpen: false,
  savedIsOpen: false,
  cancelIsOpen: false,
  removeIsOpen: false,
  freezeRequest: true,
  overviewIsOpen: false,
  userModalIsOpen: false,
  approveIsOpen: false,
  reproveIsOpen: false,
  deleteUserOpen: false,
  scheduleIsOpen: false,
  emailIsOpen: false,
  supportIsOpen: false,
  resetIsOpen: false,
  loadingIsOpen: false,
};

const modalsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_USER_MODAL:
      return { ...state, deleteUserOpen: payload };
    case USER_FORM_MODAL:
      return { ...state, userModalIsOpen: payload };
    case REQUEST_MODAL:
      return { ...state, requestIsOpen: payload };
    case CONFIRM_SEND_MODAL:
      return { ...state, confirmSendIsOpen: payload };
    case EXTENDED_CONFIRM_SEND_MODAL:
      return { ...state, extendedSendIsOpen: payload };
    case SOLICITATION_SENDED_MODAL:
      return { ...state, sendedIsOpen: payload };
    case SAVED_MODAL:
      return { ...state, savedIsOpen: payload };
    case CANCEL_MODAL:
      return { ...state, cancelIsOpen: payload };
    case REMOVE_MODAL:
      return { ...state, removeIsOpen: payload };
    case FREEZE_REQUEST:
      return { ...state, freezeRequest: payload };
    case OVERVIEW_MODAL:
      return { ...state, overviewIsOpen: payload };
    case APPROVE_MODAL:
      return { ...state, approveIsOpen: payload };
    case REPROVE_MODAL:
      return { ...state, reproveIsOpen: payload };
    case SCHEDULE_MODAL:
      return { ...state, scheduleIsOpen: payload };
    case EMAIL_MODAL:
      return { ...state, emailIsOpen: payload };
    case SUPPORT_MODAL:
      return { ...state, supportIsOpen: payload };
    case RESET_MODAL:
      return { ...state, resetIsOpen: payload };
    case LOADING:
      return { ...state, loadingIsOpen: payload };
    default:
      return state;
  }
}

export default modalsReducer;