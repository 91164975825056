import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { savedModalAction } from '../../redux/actions/modalsActions';
import { Divider } from '@mui/material';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const savedIsOpen = useSelector((reducer) => reducer.modalsReducer.savedIsOpen);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(savedModalAction(false));
    dispatch(fetchSolicitationsAction())
  }
  
  return (
    <div>
      <Dialog
        open={savedIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" sx={{ textAlign: 'center', fontSize: '25px'}}>Solicitação salva!</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center'}}>
            Solicitação salva como rascunho. Você poderá edita-la posteriormente pela lista de solicitações.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleClick } variant="contained">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}