import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';
import Remove from '../components/modals/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { removeModalAction, requestModalAction, overviewAction } from '../redux/actions/modalsActions';
import { fetchSolicitationsAction } from '../redux/actions/eventsActions';
import axiosBasf from '../axiosConfig';
import { populateDataAction } from '../redux/actions/requestActions';
import { populateFormRequest } from '../utils/eventFormUtils';
import Overview from '../components/modals/Overview';


function getStatusColor(status) {
  if (status === 'Aguardando aprovação') return '#ffc107';
  if (status === 'Aprovada') return '#008247';
  if (status === 'Reprovada') return 'red';
  return null;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome do evento',
  },
  {
    id: 'date_event',
    numeric: true,
    disablePadding: false,
    label: 'Data do evento',
  },
  {
    id: 'userCompany',
    numeric: true,
    disablePadding: false,
    label: 'Criador',
  },
  {
    id: 'responsibleEmail',
    numeric: true,
    disablePadding: false,
    label: 'Responsável',
  },
  {
    id: 'state',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'update_at',
    numeric: true,
    disablePadding: false,
    label: 'Ultima alteração',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <div>

        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="primary"
        >
          Lista de solicitações
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Filters({filterApproved, setFilterApproved, filterReproved, setFilterReproved, filterPending, setFilterPending}) {
  return (
    <Box sx={{ padding: '5px 16px', display: 'flex', alignItems: 'center', gap: '16px' }}>
      <Typography>Filtros: </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
            checked={filterApproved}
            onChange={() => setFilterApproved((prevState) => !prevState)}
            name="approved"
            sx={{color: '#008247', '&.Mui-checked': {color: '#008247'}}}
            />
          }
          label="Aprovados"
        />
        <FormControlLabel
          control={
            <Checkbox
            checked={filterReproved}
            onChange={() => setFilterReproved((prevState) => !prevState)}
            name="reproved"
            sx={{color: '#FF1744', '&.Mui-checked': {color: '#FF1744'}}}
            />
          }
          label="Reprovados"
        />
        <FormControlLabel
          control={
            <Checkbox
            checked={filterPending}
            onChange={() => setFilterPending((prevState) => !prevState)}
            name="pending"
            sx={{color: '#FFC107', '&.Mui-checked': {color: '#FFC107'}}}
            />
          }
          label="Aguardando aprovação"
        />
      </Box>
    </Box>

  )
}

export default function EnhancedTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idTarget, setIdTarget] = useState(null);
  const [filteredEventsList, setFilteredEventsList] = useState([]);
  const [filterApproved, setFilterApproved] = useState(false);
  const [filterReproved, setFilterReproved] = useState(false);
  const [filterPending, setFilterPending] = useState(false);


  const dispatch = useDispatch();
  const eventsList = useSelector((reducer) => reducer.eventsReducer.solicitations);
  const userData = useSelector((reducer) => reducer.authReducer.user);


  useEffect(() => {
    dispatch(fetchSolicitationsAction());
  }, [])

  function handleRemoveButton(id) {
    setIdTarget(id)
    dispatch(removeModalAction(true))
  }

  async function handleEditButton(id) {
    const { data } = await axiosBasf.get(`/events/getEvent/${id}`, { headers: { authorization: localStorage.getItem('token') } })
    const cleanedPopulatedData = populateFormRequest(data);
    dispatch(populateDataAction(cleanedPopulatedData));
    dispatch(requestModalAction(true));
  }

  async function handleSeeSolicitation(id) {
    setIdTarget(id)
    dispatch(overviewAction(true));
  }

  function filterEvents() {
    const filterFactor = [];
    if (filterApproved) filterFactor.push('Aprovada')
    if (filterReproved) filterFactor.push('Reprovada')
    if (filterPending) filterFactor.push('Aguardando aprovação')
    if (!filterFactor.length) return eventsList
    const filteredEvents = eventsList.filter(({state}) => filterFactor.includes(state));
    return filteredEvents;
  }


  function generateButtons(status, id) {
    const maxWidth = '200px'

    const validateButton = <Button onClick={() => handleSeeSolicitation(id)} fullWidth size="small" variant="contained">Validar</Button>;
    const removeButton = <Button onClick={() => handleRemoveButton(id)} fullWidth size="small" color="error" variant="contained">Excluir</Button>;
    const editButton = <Button onClick={() => handleEditButton(id)} fullWidth size="small" variant="contained">Editar</Button>;
    const justificativeButton = <Button onClick={() => handleSeeSolicitation(id)} fullWidth size="small" color="tertiary" variant="contained" >Ver justificativa</Button>;
    const seeSolicitationButton = <Button onClick={() => handleSeeSolicitation(id)} fullWidth size="small" color="secondary" variant="contained">Ver solicitação</Button>;
    if (status === 'Aguardando aprovação') {
      return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth }}>
          {userData.permission === 1 ? validateButton : null}
          {userData.permission === 1 ? removeButton : seeSolicitationButton}
        </div>
      )
    }
    if (status === 'Aprovada') {
      return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth }}>
          {seeSolicitationButton}
        </div>
      )
    }
    if (status === 'Reprovada') {
      return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth }}>
          {justificativeButton}
        </div>
      )
    }
    if (status === 'Rascunho') {
      return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth }}>
          {editButton}
          {removeButton}
        </div>
      )
    }
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = eventsList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - eventsList.length) : 0;

  return (
    // <Box sx={{ width: '100%', padding: '30px', backgroundColor: 'red'}}>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, padding: '15px 25px' }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {eventsList.length ? <Filters
        filterApproved={filterApproved}
        setFilterApproved={setFilterApproved}
        filterReproved={filterReproved}
        setFilterReproved={setFilterReproved}
        filterPending={filterPending}
        setFilterPending={setFilterPending}
        /> : null}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={eventsList.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(filterEvents(), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                          <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          />
                        </TableCell> */}
                      <TableCell
                        // sx={{ padding: '16px'}}
                        align="left"
                        component="th"
                        id={labelId}
                        scope="row"
                      // padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name ? row.name : 'Não preenchido'}</TableCell>
                      <TableCell align="left">{moment(row.date_event).format('DD/MM/YYYY') !== 'Invalid date' ? moment(row.date_event).format('DD/MM/YYYY') : 'Não preenchido'}</TableCell>
                      <TableCell align="left">{row.userCompany}</TableCell>
                      <TableCell align="left">{row.responsibleEmail ? row.responsibleEmail : 'Não preenchido'}</TableCell>
                      <TableCell align="left" sx={{ color: getStatusColor(row.state) }}>{row.state}</TableCell>
                      <TableCell align="left">{moment(row.update_at).format('DD/MM/YYYY') ? moment(row.update_at).format('DD/MM/YYYY') : 'Não preenchido'}</TableCell>
                      <TableCell align="left" sx={{ minWidth: '180px' }}>{generateButtons(row.state, row.id)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={eventsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Remove idTarget={idTarget} />
      <Overview idTarget={idTarget} />
    </Box>
  );
}