import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { extendedConfirmSendModalAction, requestModalAction } from '../../redux/actions/modalsActions';
import { clearDateAndTime } from '../../redux/actions/requestActions';
import axiosBasf from '../../axiosConfig';
import { generateBodyForm } from '../../utils/eventFormUtils'
import { Divider } from '@mui/material';
import { addDataAction } from '../../redux/actions/requestActions';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmExtendedSend() {
  const extendedSendIsOpen = useSelector((reducer) => reducer.modalsReducer.extendedSendIsOpen);
  const formData = useSelector((reducer) => reducer.requestReducer)
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(extendedConfirmSendModalAction(false));
    dispatch(requestModalAction(true));
  }

  const handleSendClick = () => {
    dispatch(extendedConfirmSendModalAction(false));
    axiosBasf.post('/events/save', generateBodyForm(formData, 1), { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(extendedConfirmSendModalAction(false));
        dispatch(fetchSolicitationsAction())
        dispatch(clearDateAndTime())
        dispatch(requestModalAction(true));

      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={extendedSendIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBackClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" size="large" sx={{ textAlign: 'center', fontSize: '25px', }}>Atenção!</DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center' }}>
            Você optou por extender o evento atual para mais um dia.
            <br />
            <br />
            Caso deseje continuar, a solicitação com a data atual será enviada para aprovação da administração, em seguida,
            será aberto um novo modal para que você preencha a data e o horário do próximo dia.
            <br />
            <br />
            Após o envio não, será possivel editar a data que já foi enviada.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button onClick={handleSendClick} variant="contained">Enviar</Button>
          <Button onClick={handleBackClick} variant="contained" color="error">Voltar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}