import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { approveAction, schduleAction } from '../../redux/actions/modalsActions';
import { fetchApprovedSolicitationsAction, fetchSolicitationsAction } from '../../redux/actions/eventsActions';
import axiosBasf from '../../axiosConfig';
import { Divider } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Schedule({ idTarget }) {
  const scheduleIsOpen = useSelector((reducer) => reducer.modalsReducer.scheduleIsOpen);
  const actualEvent = useSelector((reducer) => reducer.eventsReducer.approvedSolicitations).find(({id}) => idTarget === id);
  const dispatch = useDispatch();

  const handleNoClick = () => {
    dispatch(schduleAction(false));
  }

  const handleApproveClick = () => {
    const newScheduleState = actualEvent.schedule_basf === 1 ? 0 : 1
    axiosBasf.post(`events/update-schedule/${idTarget}`, { schedule_basf: newScheduleState }, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch(schduleAction(false));
        dispatch(fetchApprovedSolicitationsAction())
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={scheduleIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNoClick} 
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" size="large" sx={{ textAlign: 'center', fontSize: '25px' }}>Atenção!</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center' }}>
            { actualEvent && actualEvent.schedule_basf === 1
            ? 'Tem certeza que deseja remover este evento da agenda BASF?'
            : 'Tem certeza que deseja inserir este evento na agenda BASF?'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button
            onClick={handleApproveClick}
            variant="contained"
            >
            { actualEvent && actualEvent.schedule_basf === 1
            ? 'Sim, remover'
            : 'Sim, inserir'
            }
          </Button>
          <Button
          onClick={handleNoClick}
          variant="contained"
          color="error"
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}