import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Paper, TextField, Typography, Link, ThemeProvider, Box, Slide, Divider } from '@mui/material'
import { loginAction } from '../redux/actions/authActions';
import { ToastContainer, toast } from 'react-toastify';
import { colors } from '../styles'
import validator from 'validator'
import theme from '../theme';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jwt-decode';
import EmailSended from '../components/modals/EmailSended';
import { emailAction, resetAction } from '../redux/actions/modalsActions';
import axiosBasf from '../axiosConfig';
import ResetSuccess from '../components/modals/ResetSuccess';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [errors, setErrors] = useState({ password: { status: false, message: '' }, confirmation: { status: false, message: '' } });
  const [params] = useSearchParams();


  useEffect(() => {
    if (errors.password.status || errors.confirmation.status) return setButtonDisabled(true);
    return setButtonDisabled(false);
  }, [errors]);

  useEffect(() => {
    setButtonDisabled(true);
  }, []);

  const handleSend = () => {
    const token = params.get('token');
    const email = params.get('email');
    // dispatch(emailAction(true))
    axiosBasf.post('/update-password', { email, new_password: password, new_password_conf: confirmation, token })
      .then((response) => {
        localStorage.removeItem('token')
        dispatch(resetAction(true))
      })
      .catch((err) => {
        toast.error('E-mail ou senha inválido')
      })
  }

  const handlePassword = ({ target }) => {
    setErrors((previousState) => ({ ...previousState, password: { status: false, message: '' } }))
    setPassword(target.value);
    if (!target.value.trim().length) {
      setErrors((previousState) => ({ ...previousState, password: { status: true, message: 'A senha é obrigatória' } }))
    }
    if (target.value.trim() !== confirmation) {
      setErrors((previousState) => ({ ...previousState, confirmation: { status: true, message: 'As senhas são diferentes' } }))
    }
  }
  const handleConfirmation = ({ target }) => {
    setErrors((previousState) => ({ ...previousState, confirmation: { status: false, message: '' } }))
    setConfirmation(target.value)
    if (target.value.trim() !== password) {
      setErrors((previousState) => ({ ...previousState, confirmation: { status: true, message: 'As senhas são diferentes' } }))
    }
  }

  const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
  }

  const handleKeyPress = ({ key }) => {
    if (buttonDisabled && key === 'Enter') handleSend();
  }

  return (

    <Box sx={{ display: 'flex' }}>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 2, backgroundColor: colors.green, backgroundImage: 'url(assets/img/login-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <img src="assets/img/logo_basf.png" alt="BASF logo" style={{ height: '150px' }} />
        {/* <Box sx={{ maxWidth: '150px' }}>
          <Typography variant="h5" size="large" sx={{ color: 'white', fontWeight: '800'}}>Plataforma Administrativa de Eventos</Typography>
        </Box> */}
      </Box>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 4, backgroundColor: colors.gray }}>
        <Box sx={{ width: '100%' }}>
          <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '30px', margin: 'auto', maxWidth: "400px" }}>
            <Typography variant="title-primary" align="center">Digite sua nova senha</Typography>
            <Typography variant="h8" align="center" color={colors.black}>Preencha os campos abaixo para alterar sua senha.</Typography>
            <TextField
              error={errors.password.status}
              type="password"
              size="small"
              helperText={errors.password.message}
              label="Insira sua nova senha"
              onChange={handlePassword}
              value={password}
              onKeyPress={handleKeyPress}
            />
            <TextField
              error={errors.confirmation.status}
              size="small"
              helperText={errors.confirmation.message}
              label="Confirme sua nova senha"
              onChange={handleConfirmation}
              value={confirmation}
              onKeyPress={handleKeyPress}
              type="password"
            />
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Button fullWidth disabled={buttonDisabled} variant="contained" onClick={handleSend}>Enviar</Button>
            </Box>
          </Paper>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <EmailSended />
          {/* Same as */}
        </Box>
      </Box>
      <ResetSuccess />
    </Box>
  )
}