import { LOGIN, LOGOUT } from '../actions/authActions';


const INITIAL_STATE = {logged: false, user: {}};

const authReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch(type) {
  case LOGIN:
    return {...state, logged: true, user: payload}
  case LOGOUT:
    return INITIAL_STATE
  default:
    return state;
  }
}

export default authReducer;