import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';
import { overviewAction, approveAction, reproveAction } from '../../redux/actions/modalsActions';
import axiosBasf from '../../axiosConfig';
import moment from 'moment';
import Approve from './Aprove';
import Reprove from './Reprove';

export default function Overview({ idTarget }) {
  const dispatch = useDispatch();
  const overviewIsOpen = useSelector((reducer) => reducer.modalsReducer.overviewIsOpen);
  const solicitations = useSelector((reducer) => reducer.eventsReducer.solicitations);
  const userData = useSelector((reducer) => reducer.authReducer.user);

  const [eventData, setEventData] = useState([]);
  const [typeTransmissions, setTypeTransmissions] = useState([]);
  const [typeInteractions, setTypeInteractions] = useState([]);
  // const handleClickOpen = (scrollType) => () => {
  // };

  const handleClose = () => {
    dispatch(overviewAction(false));
  };

  const handleApprove = () => {
    dispatch(overviewAction(false));
    dispatch(approveAction(true));
  }
  const handleReprove = () => {
    dispatch(overviewAction(false));
    dispatch(reproveAction(true));
  }

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (overviewIsOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [overviewIsOpen]);

  useEffect(() => {
    if (!idTarget) return;
    axiosBasf.get(`/events/getEvent/${idTarget}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => setEventData(response.data))
      .catch((err) => console.log(err));

  }, [idTarget, solicitations])

  useEffect(() => {
    if (!idTarget) return;
    axiosBasf.get(`type_transmission/find_by_event/${idTarget}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => setTypeTransmissions(response.data))
      .catch((err) => console.log(err));
  }, [idTarget, solicitations])

  useEffect(() => {
    if (!idTarget) return;
    axiosBasf.get(`type_interactions/find_by_event/${idTarget}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        setTypeInteractions(response.data)
      })
      .catch((err) => console.log(err));
  }, [idTarget, solicitations])



  return (
    <div>
      <Dialog
        open={overviewIsOpen}
        onClose={handleClose}
        scroll='body'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" color={eventData.id_state !== 3 ? 'primary' : 'error'}>
          {eventData.id_state !== 3
            ? 'Informações do evento'
            : 'Evento reprovado'}
        </DialogTitle>
        <Divider />
        <DialogContent dividers={false} >
          <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '450px', maxWidth: '400px', gap: '15px' }}>
            {eventData.desapproved_justification
              ? <><DialogContentText sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ff1744', borderRadius: '8px', padding: '15px' }}>
                <span style={{ color: 'white', fontWeight: '800', textAlign: 'center' }}>Justificativa</span>
                <span style={{ color: 'white', textAlign: 'center' }}>{eventData.desapproved_justification}</span>
              </DialogContentText>
                <Divider variante="middle" /></>
              : null}
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontWeight: '800' }}>Nome do evento</span>
              <span>{eventData.name}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>E-mail do responsável pelo evento</span>
              <span>{eventData.responsibleEmail}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>E-mail do solicitante</span>
              <span>{eventData.requesterEmail}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Área do repsonsável pelo evento</span>
              <span>{eventData.occupation_area}</span>
            </DialogContentText>
            <Divider variant="middle" />
            <Box sx={{ display: 'flex' }}>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Data do evento</span>
                <span>{moment(eventData.date_event).format('DD/MM/YYYY')}</span>
              </DialogContentText>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Horário do evento</span>
                <span>{`Das ${moment(eventData.start_time).format('HH:mm')} às ${moment(eventData.end_time).format('HH:mm')}`}</span>
              </DialogContentText>
            </Box>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Tipo do evento</span>
              <span>{eventData.open_all_users === 1 ? 'Aberto para todos os usuários' : 'Fechado, apenas usuários especificos'}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Onde será realizado</span>
              <span>{eventData.local}</span>
            </DialogContentText>
            <Divider variant="middle" />
            <Box>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Tipo de transmissão</span>
              </DialogContentText>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {typeTransmissions.map(({ id, description }) => <span style={{ color: 'rgba(0, 0, 0, 0.6)' }} key={id}>{description}</span>)}
              </Box>
            </Box>
            <Box>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Tipo de interação</span>
              </DialogContentText>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {typeInteractions.map(({ id, name }) => <span style={{ color: 'rgba(0, 0, 0, 0.6)' }} key={id}>{name}</span>)}
              </Box>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ display: 'flex' }}>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>O evento terá chat?</span>
                <span>{eventData.event_chat === 1 ? 'Sim' : 'Não'}</span>
              </DialogContentText>
              <DialogContentText sx={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>O evento terá quiz?</span>
                <span>{eventData.event_quiz === 1 ? 'Sim' : 'Não'}</span>
              </DialogContentText>
            </Box>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Público alvo</span>
              <span>{eventData.target_audience}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Cultivo</span>
              <span>{eventData.target_crop}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Objetivo</span>
              <span>{eventData.objective}</span>
            </DialogContentText>
            <Divider variant="middle" />

            {/* <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Resultados esperados</span>
              <span>{eventData.expected_result}</span>
            </DialogContentText> */}
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Participantes estimados</span>
              <span>{eventData.expected_num_users}</span>
            </DialogContentText>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Publicar na agenda BASF</span>
              <span>{eventData.schedule_basf === 1 ? 'Sim' : 'Não'}</span>
            </DialogContentText>
          <Divider />
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#008247', fontSize: '16px', fontWeight: '800' }}>Observação</span>
            <span>{eventData.observation ? eventData.observation : 'Sem observações'}</span>
          </DialogContentText>
          </Box>

        </DialogContent>
        {eventData.id_state === 1 && userData.permission === 1
          ? <DialogActions align="center">
            <Button variant="contained" onClick={handleApprove}>Aprovar</Button>
            <Button variant="contained" color="error" onClick={handleReprove}>Reprovar</Button>
          </DialogActions>
          : null}


      </Dialog>
      <Approve idTarget={idTarget} />
      <Reprove idTarget={idTarget} />
    </div>
  );
}
