import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { emailAction } from '../../redux/actions/modalsActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate, useLocation } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const location = useLocation();
  const emailIsOpen = useSelector((reducer) => reducer.modalsReducer.emailIsOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(emailAction(false));
    if(location.pathname !== '/users') navigate('/login');    
  }
  
  return (
    <div>
      <Dialog
        open={emailIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" sx={{ fontSize: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <CheckCircleIcon sx={{ fontSize: '80px' }}/>
          Cheque seu e-mail
        </DialogTitle>
        {/* <Divider /> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', maxWidth: '450px'}}>
          Você receberá em até 10 minutos um e-mail com os passos para redefinir sua senha.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleClick } variant="contained">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}