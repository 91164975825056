import React, { useEffect } from 'react'
import { Box, Paper } from '@mui/material'
import Table from '../components/users/Table'

export default function Users() {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, padding: '15px 50px' }}>
        <Table />
      </Paper>
    </Box>
  )
}