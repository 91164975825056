import { TIP_ENABLE_CHROME, TIP_TOGGLE_CHROME } from "../actions/tipAction";


const INITIAL_STATE = {
  tipChrome: false,
}

export default function tipReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TIP_ENABLE_CHROME:
      return { ...state, tipChrome: true};
    case TIP_TOGGLE_CHROME:
      return { ...state, tipChrome: !state.tipChrome};
    default:
      return state;
  }
}