import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { approveAction } from '../../redux/actions/modalsActions';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';
import axiosBasf from '../../axiosConfig';
import { Divider } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Approve({ idTarget }) {
  const approveIsOpen = useSelector((reducer) => reducer.modalsReducer.approveIsOpen);
  const dispatch = useDispatch();

  const handleNoClick = () => {
    dispatch(approveAction(false));
  }

  const handleApproveClick = () => {

    axiosBasf.post(`events/approve/${idTarget}`, { id_state: 2 }, { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {

        dispatch(approveAction(false));
        dispatch(fetchSolicitationsAction())
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={approveIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNoClick} //backclick
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="primary" size="large" sx={{ textAlign: 'center', fontSize: '25px' }}>Atenção!</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center' }}>
            Tem certeza que deseja aprovar esta solicitação?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button onClick={handleApproveClick} variant="contained">Aprovar</Button>
          <Button onClick={handleNoClick} variant="contained" color="error">Não</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}