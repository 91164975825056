import { createEvent } from "ics";
import moment from 'moment';

export default function generateIcs({name, date_event, start_time, end_time}) {
  let link = '';
  // const durationInHours = moment.duration(moment(end_time).diff(start_time)).asHours();
  const durationInMinutes = moment.duration(moment(end_time).diff(start_time)).asMinutes();
  
  const teste  = {
    title: name,
    start: [
      moment(date_event).year(),
      moment(date_event).month() + 1,
      moment(date_event).date(),
      moment(start_time).hours(),
      moment(start_time).minutes(),
      moment(start_time).minute()
    ],
    duration: {
      // hours: Math.floor(durationInHours),
      minutes: durationInMinutes,
    }
  }

  createEvent(teste, (err, value) => {
      const file = new Blob([value], {type: 'text/calendar;charset=utf-8', name: 'teste.ics'});
      link = URL.createObjectURL(file)
  })
  return link

}