import axiosBasf from '../../axiosConfig';

export const GET_SOLICITATIONS = 'GET_SOLICITATIONS';
export const GET_APPROVED_SOLICITATIONS = 'GET_APPROVED_SOLICITATIONS'

export const getSolicitationsAction = (payload) => ({
  type: GET_SOLICITATIONS,
  payload
})
export const getApprovedSolicitationsAction = (payload) => ({
  type: GET_APPROVED_SOLICITATIONS,
  payload
})

export const fetchSolicitationsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosBasf.get('/events/list', { headers: {authorization: localStorage.getItem('token')}})
    return dispatch(getSolicitationsAction(data));
  } catch (err) {
    console.log(err)
  }
}
export const fetchApprovedSolicitationsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosBasf.get('/events/approved-list', { headers: {authorization: localStorage.getItem('token')}})
    return dispatch(getApprovedSolicitationsAction(data));
  } catch (err) {
    console.log(err)
  }
}