import { combineReducers } from 'redux';
import authReducer from './auth';
import modalsReducer from './modals';
import requestReducer from './request'
import eventsReducer from './events';
import userReducer from './user';
import userReducerModal from './userModal';
import tipReducer from './tipReducer';

const rootReducer = combineReducers({ authReducer, modalsReducer, requestReducer, eventsReducer, userReducerModal, userReducer, tipReducer })

export default rootReducer;