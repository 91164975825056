import { createTheme } from '@mui/material';

const Theme = createTheme({
  palette: {
    primary: {
      light: '#339b6b',
      main: '#008247',
      dark: '#005b31',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#1769aa',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#ffcd38',
      main: '#ffc107',
      dark: '#b28704',
      contrastText: '#fff',
    },
    dark: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
    },
    error: {
      light: '#ff4569',
      main: '#ff1744',
      dark: '#b2102f',
      contrastText: '#fff',
    },
  },
  typography: {
    'title-primary': {
      color: '#008247',
      fontSize: '26px',
      fontWeight: 800,
    },
    'body-primary': {
      fontSize: '16px',
      color: '#008247',
      fontWeight: 800,
    }
  },
});

export default Theme;