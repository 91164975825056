export const monthPtBr = (month)  => {
  switch (month) {
    case 'January':
      return 'Janeiro';
    case 'March':
      return 'Março';
    case 'April':
      return 'Abril';
    case 'May':
      return 'Maio';
    case 'June':
      return 'Junho';
    case 'July':
      return 'Julho';
    case 'August':
      return 'Agosto';
    case 'September':
      return 'Setembro';
    case 'October':
      return 'Outubro';
    case 'November':
      return 'Novembro';
    case 'December':
      return 'Dezembro';
      default:
        return '';
  }
}

export const weekDayPtBr = (day) => {
  switch (day) {
    case 1:
      return 'Segunda-feira';
    case 2:
      return 'Terça-feira';
    case 3:
      return 'Quarta-feira';
    case 4:
      return 'Quinta-feira';
    case 5:
      return 'Sexta-feira';
    case 6:
      return 'Sábado';
    case 7:
      return 'Domingo';
      default:
        return '';
  }

}
