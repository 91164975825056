import axiosBasf from '../../axiosConfig';

export const GET_USERS = 'GET_USERS';

export const getUsersAction = (payload) => ({
  type: GET_USERS,
  payload
})

export const fetchUsersAction = () => async (dispatch) => {
  try {
    const { data } = await axiosBasf.get('/user/list', { headers: { authorization: localStorage.getItem('token') } })
    return dispatch(getUsersAction(data));
  } catch (err) {
    console.log(err)
  }
}


/*
export const fetchTokenThunk = () => async (dispatch) => {
  try {
    const fetchApi = await fetch('https://opentdb.com/api_token.php?command=request');
    const json = await fetchApi.json();
    const { token } = json;
    return dispatch(fetchTokenSuccess(token));
  } catch (error) {
    return dispatch(fetchTokenFail(error));
  }
};

*/