import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { removeModalAction } from '../../redux/actions/modalsActions';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';
import axiosBasf from '../../axiosConfig';
import { Divider, Typography } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Remove({ idTarget}) {
  const removeIsOpen = useSelector((reducer) => reducer.modalsReducer.removeIsOpen);
  const dispatch = useDispatch();

  const handleNoClick = () => {
    dispatch(removeModalAction(false));
  }
  
  const handleRemoveClick = () => {
    axiosBasf.delete(`events/delete/${idTarget}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(removeModalAction(false));
        dispatch(fetchSolicitationsAction())
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={removeIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNoClick} //backclick
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error" size="large" sx={{ textAlign: 'center', fontSize: '25px'}}>Atenção!</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center'}}>
            Tem certeza que deseja remover esta solicitação?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleRemoveClick } variant="contained">Sim, remover</Button>
          <Button onClick={handleNoClick} variant="contained" color="error">Não</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}