import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button } from '@mui/material';
import moment from 'moment';
import Remove from '../components/modals/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { overviewAction } from '../redux/actions/modalsActions';
import { fetchApprovedSolicitationsAction } from '../redux/actions/eventsActions';
import Overview from '../components/modals/Overview';
import Schedule from '../components/modals/Schedule';
import generateIcs from '../utils/generateIcs';


function getStatusColor(status) {
  if (status === 1) return '#008247';
  return '#ffc107';
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome do evento',
  },
  {
    id: 'date_event',
    numeric: true,
    disablePadding: false,
    label: 'Data do evento',
  },
  // {
  //   id: 'start_time',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Horário',
  // },
  {
    id: 'responsibleEmail',
    numeric: true,
    disablePadding: false,
    label: 'E-mail Responsável',
  },
  {
    id: 'schedule_basf',
    numeric: true,
    disablePadding: false,
    label: 'Publicado na agenda Interna',
  },
  {
    id: 'ICS',
    numeric: true,
    disablePadding: false,
    label: 'ICS',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <div>

        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="primary"
        >
          Lista de solicitações
        </Typography>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
          <TextField size="small" label="Pesquisar por nome do evento" variant="outlined" sx={{ margin: '15px 0'}}/>
          <SearchIcon color="primary" sx={{ fontSize: '30px' }}/>
        </div> */}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [idTarget, setIdTarget] = useState(null);

  const dispatch = useDispatch();
  const approvedSolicitations = useSelector((reducer) => reducer.eventsReducer.approvedSolicitations);
  const userData = useSelector((reducer) => reducer.authReducer.user);
  const navigate = useNavigate();

  
  useEffect(() => {
    dispatch(fetchApprovedSolicitationsAction());
  }, [])

  async function handleSeeSolicitation(id) {
    setIdTarget(id)
    dispatch(overviewAction(true));
  }

  // function handleSchedule(id) {
  //   setIdTarget(id)
  //  dispatch(schduleAction(true))
  // }

  // function generateSchedule(schedule, id) {
  //   return (
  //       <RadioGroup
  //         row
  //         name="row-radio-buttons-group"
  //         sx={{ display: 'flex', flexDirection: 'row', minWidth: '100px'}}
  //         value={schedule}
  //         onChange={() => handleSchedule(id)}
  //       >
  //         <FormControlLabel value="1" control={<Radio size="small" />} label="Sim" />
  //         <FormControlLabel value="0" control={<Radio size="small" />} label="Não" />
  //       </RadioGroup>
  //     // <>
  //     //   <Checkbox defaultChecked />
  //     // </>
  //   )
  // }
  
  function handleDashboard(id) {
    navigate(`/dashboard/${id}`)
  }

  function generateButtons(schedule, id) {
    const width = '240px'

    const seeSolicitationButton = <Button fullWidth onClick={() => handleSeeSolicitation(id)} size="small" color="primary" variant="contained">Ver evento</Button>;
    const dashboardButton = <Button  onClick={ () => handleDashboard(id) }fullWidth size="small" color="secondary" variant="contained">Dashboard</Button>;
    if(schedule === '0') {
      return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', width}}>
          { seeSolicitationButton }
          { dashboardButton }
        </div>
      )
    }
    return (
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', width}}>
        { seeSolicitationButton }
        { dashboardButton }
      </div>
    )
  }
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = approvedSolicitations.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approvedSolicitations.length) : 0;

  return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, padding: '15px 25px' }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={approvedSolicitations.length}
              />
              <TableBody>
                {stableSort(approvedSolicitations, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        >
                        <TableCell
                        align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{`${moment(row.date_event).format('DD/MM/YYYY')} às ${moment(row.start_time).format('HH:mm')}`}</TableCell>
                        <TableCell align="left">{row.responsibleEmail}</TableCell>
                        <TableCell align="left" >{row.schedule_basf === 1 ? 'Sim' : 'Não'}</TableCell>
                        <TableCell align="left"><a href={generateIcs(row)} download="event.ics" style={{ color: 'green', textDecoration: 'underline'}}>Download</a></TableCell>
                        <TableCell align="left" sx={{ minWidth: '245px'}}>{generateButtons(row.schdule_basf, row.id)}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={approvedSolicitations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <Remove idTarget={idTarget}/>
        <Overview idTarget={idTarget}/>
        <Schedule idTarget={idTarget} />
    </Box>
  );
}