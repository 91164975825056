export const TIP_TOGGLE_CHROME = 'TIP_CHROME';
export const TIP_ENABLE_CHROME = 'TIP_ENABLE_CRHOME';

export const tipToggleChromeAction = () => ({
  type: TIP_TOGGLE_CHROME,
})
export const tipEnableChromeAction = (payload) => ({
  type: TIP_ENABLE_CHROME,
  payload
})
