import { useEffect, useState } from 'react';
import { Button, Container, Paper, stepButtonClasses, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import axiosBasf from '../axiosConfig';
import jwt from 'jwt-decode';
import validator from 'validator';
import { ErrorOutlineSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const [userId, setUserId] = useState('');
  const [nivelAccess, setNivelAccess] = useState('')
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [errors, setErrors] = useState({ name: { status: false, message: '' }, email: { status: false, message: '' } });

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(jwt(token).data.id);
    axiosBasf(`user/getUser/${userId}`, { headers: { authorization: token } })
      .then((response) => {
        console.log(response.data[0])
        setUserId(userId);
        setNivelAccess(response.data[0].id_nivel_access)
        setName(response.data[0].name);
        setEmail(response.data[0].email);
      })
      .catch(err => console.log(err.request))
  }, [])

  useEffect(() => {
    if (errors.name.status || errors.email.status) return setDisabledButton(true);
    setDisabledButton(false);
  }, [errors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      id_user: userId,
      id_nivel_access: nivelAccess,
      name,
      pass: password,
      email,
    }
    axiosBasf.post('/user/save', formData, { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => navigate('/home'))
      .catch((_err) => {
        console.log(_err)
        setErrors({ ...errors, email: { status: true, message: 'Este e-mail já está em uso por outro usuário' } })
      })
  }

  const handleNameChange = ({ target }) => {
    setErrors({ ...errors, name: { status: false, message: '' } })
    setName(target.value)
    if (!target.value.trim().length) {
      setErrors({ ...errors, name: { status: true, message: 'O campo nome é obrigatório' } })
    }

  }
  const handleEmailChange = ({ target }) => {
    setErrors({ ...errors, email: { status: false, message: '' } })
    setEmail(target.value)
    if (!target.value.trim().length || !validator.isEmail(target.value)) {
      setErrors({ ...errors, email: { status: true, message: 'Insira um e-mail valido' } })
    }
  }


  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', padding: '30px', gap: '15px', alignItems: 'center', minWidth: '400px' }}>
        <AccountCircleIcon color="primary" sx={{ fontSize: '60px' }} />
        <Typography color="primary" sx={{ fontSize: '20px', fontWeight: '800' }}>Suas informações</Typography>
        <TextField
          label="Nome *"
          name="name"
          value={name}
          onChange={handleNameChange}
          fullWidth sx={{ mt: 1 }}
          size="small"
          error={errors.name.status}
          helperText={errors.name.message}
        />
        <TextField
          label="Email *"
          name="email"
          value={email}
          helperText={errors.email.message}
          error={errors.email.status}
          onChange={handleEmailChange}
          fullWidth
          size="small"
        />
        <TextField
          label="Senha *"
          name="pass"
          value={password}
          type="password"
          onChange={(({ target }) => setPassword(target.value))}
          fullWidth
          size="small"
        />
        <Button fullWidth variant="contained" disabled={disabledButton} type="submit">Salvar</Button>
      </Paper>
    </Box>
  )
}