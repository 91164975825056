import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userFormModal } from '../../redux/actions/modalsActions';
import { addDataAction } from '../../redux/actions/userModalActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';
import { colors } from '../../styles';
import { fetchUsersAction } from '../../redux/actions/users'
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  ListItemText
} from "@mui/material";
import axiosBasf from '../../axiosConfig';
import validator from 'validator';
import { loginAction } from '../../redux/actions/authActions';

export default function UserFormModal({ mailError, setMailError, mailTextError, setMailTextError, idTarget }) {
  const [nivelAccess, setNivelAccess] = useState([])
  const fullScreen = useMediaQuery(theme.breakpoints.down(''));

  const dispatch = useDispatch();
  const userModalIsOpen = useSelector((reducer) => reducer.modalsReducer.userModalIsOpen);
  const formData = useSelector((reducer) => reducer.userReducerModal);

  useEffect(() => {
    axiosBasf('/nivel_access/list', { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        setNivelAccess(response.data);
      })
      .catch((err) => console.log(err.request))
  }, [])

  const handleClose = () => {
    dispatch(userFormModal(false));
  };

  const validateFields = () => {
    if (!formData.name.trim().length) return false;
    if (!formData.email.trim().length || !validator.isEmail(formData.email)) return false;
    if (!formData.pass.trim().length && formData.id_user === 0) return false;
    if (!formData.id_nivel_access) return false;
    return true;
  }

  const handleSend = () => {
    axiosBasf.post('/user/save', formData, { headers: { authorization: localStorage.getItem('token') } })
      .then(response => {
        dispatch(userFormModal(false));
        dispatch(fetchUsersAction());
        const userAuthData = JSON.parse(localStorage.getItem('user'))
        if (formData.id_user === userAuthData.id) {
          const newUserData = { ...userAuthData, name: formData.name }
          localStorage.setItem('user', JSON.stringify(newUserData));
          dispatch(loginAction(newUserData))
        }
      })
      .catch((err) => {
        console.log(err.request)
        setMailError(true)
        setMailTextError('Este email já está em uso!')
      })
  }

  const handleCancel = () => {
    dispatch(userFormModal(false));
  }

  const handleEmailChange = (value) => {
    dispatch(addDataAction({ field: 'email', data: value }))
    setMailError(false);
    setMailTextError('');
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={userModalIsOpen}
      onClose={handleClose}
      scroll="body"
    >
      <form>
        <Box sx={{ width: '475px', p: 3 }}>
          <DialogTitle color={colors.green}> {"Cadastrar novo usuário"} </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TextField
              label="Nome *"
              name="name"
              value={formData.name}
              onChange={(({ target }) => dispatch(addDataAction({ field: 'name', data: target.value })))}
              fullWidth sx={{ mt: 1 }}
            />
            <TextField
              label="Email *"
              name="email"
              value={formData.email}
              helperText={mailTextError}
              error={mailError}
              onChange={(({ target }) => handleEmailChange(target.value))}
              fullWidth
            />
            <TextField
              label="Senha *"
              name="pass"
              value={formData.pass}
              type="password"
              onChange={(({ target }) => dispatch(addDataAction({ field: 'pass', data: target.value })))}
              fullWidth
            />
            <InputLabel id="teste">Permissão *</InputLabel>
            <Select
              id="id-nivel-access"
              name="nivel-access"
              // value={formData.eventType === '1' ? openLocalsList[0] : closedLocalsList[0]}
              value={formData.id_nivel_access}
              onChange={({ target }) => dispatch(addDataAction({ field: 'id_nivel_access', data: target.value }))}
            >
              {nivelAccess.map(({ id, description }) => (
                <MenuItem key={id} value={id}>
                  <ListItemText primary={description} />
                </MenuItem>))}
            </Select>

            {/*   <InputLabel id="teste">Região *</InputLabel>
            <Select
              id=""
              name=""
              value={formData.eventType === '1' ? openLocalsList[0] : closedLocalsList[0]}
              value={formData.id_nivel_access}
              onChange={({ target }) => dispatch(addDataAction({ field: 'id_nivel_access', data: target.value }))}
            >
              {listRegions.map(({ id, description }) => (
                <MenuItem key={id} value={id}>
                  <ListItemText primary={description} />
                </MenuItem>))}
            </Select> */}

          </DialogContent>
          <DialogActions>

            <Button disabled={!validateFields()} variant="contained" onClick={handleSend}>
              Salvar
            </Button>
            {/* <Button variant="outlined" color="primary" onClick={handleClose}>
              Salvar e duplicar
            </Button> */}
            <Button variant="contained" color="error" onClick={handleCancel}>
              Cancelar
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  )
}
