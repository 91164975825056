import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, TextField, Typography, Link, Box, Slide, Divider } from '@mui/material'
import { loginAction } from '../redux/actions/authActions';
import { ToastContainer, toast } from 'react-toastify';
import { colors } from '../styles'
import validator from 'validator'
import axiosBasf from '../axiosConfig';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jwt-decode';
import ForgetPassword from '../components/modals/EmailSended';

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: { status: false, message: '' }, password: { status: false, message: '' } })

  useEffect(() => {
    if (!email.trim().length) return setButtonDisabled(true);
    if (!validator.isEmail(email)) return setButtonDisabled(true);
    if (!(password.trim().length)) return setButtonDisabled(true);
    return setButtonDisabled(false);
  }, [email, password]);

  const handleLogin = () => {
    axiosBasf.post('/login', { email, password })
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem('token', token);
        const userData = jwt(token).data;
        dispatch(loginAction(userData))
        navigate('/home')
      })
      .catch((err) => {
        toast.error('E-mail ou senha inválido')
      })
  }

  const handleEmail = ({ target }) => {
    setErrors((errors) => ({ ...errors, email: { status: false, message: '' } }));
    const { value } = target
    setEmail(value);
    if (!validator.isEmail(value)) return setErrors((errors) => ({ ...errors, email: { status: true, message: 'Insira um e-mail válido.' } }))
    setErrors((errors) => ({ ...errors, email: { status: false, message: '' } }));
  }

  const handlePassword = ({ target }) => {
    setErrors((errors) => ({ ...errors, password: { status: false, message: '' } }));
    const { value } = target;
    setPassword(value);
    if (!(value.trim().length)) return setErrors((errors) => ({ ...errors, password: { status: true, message: 'Preencha a senha.' } }))
    setErrors((errors) => ({ ...errors, password: { status: false, message: '' } }));
  }

  const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
  }

  const handleKeyPress = ({ key }) => {
    if (key === 'Enter') handleLogin();
  }

  return (

    <Box sx={{ display: 'flex' }}>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 2, backgroundColor: colors.green, backgroundImage: 'url(assets/img/login-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        
         <Box>
          <Typography variant="h5" size="large" sx={{ color: 'white', fontWeight: '800'}}>Plataforma<br/>Administrativa<br/>de Eventos</Typography>
        </Box> 
      </Box>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 4, backgroundColor: colors.gray }}>
        <Box sx={{ width: '100%' }}>
          <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '30px', margin: 'auto', maxWidth: "400px" }}>
            <Typography variant="title-primary" align="center">Bem-Vindo!</Typography>
            <Typography variant="h8" align="center" color={colors.black}>Faça seu login para entrar</Typography>
            <TextField
              error={errors.email.status}
              size="small"
              helperText={errors.email.message}
              label="E-mail"
              onChange={handleEmail}
              value={email}
              onKeyPress={handleKeyPress}
            />
            <TextField
              type={'password'}
              error={errors.password.status}
              size="small"
              helperText={errors.password.message}
              label="Senha"
              onChange={handlePassword}
              value={password}
              onKeyPress={handleKeyPress}
            />
            <Link align="center" href="/forget-password" color={colors.green}>Esqueci minha senha</Link>
            <Button disabled={buttonDisabled} variant="contained" onClick={handleLogin}>Entrar</Button>
          </Paper>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <ForgetPassword />
          {/* Same as */}
        </Box>
      </Box>
    </Box>
  )
}