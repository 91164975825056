import moment from 'moment';

export function generateBodyForm(formData) {
  const preStamp = '2022-01-01T'
  return {
    id: formData.id ? formData.id : 0,
    id_user_company: 1,
    id_state: 1,
    name: formData.name,
    responsibleEmail: formData.responsibleEmail,
    requesterEmail: formData.requesterEmail,
    occupation_area: formData.occupationArea,
    date_event: formData.date ? moment(formData.date).format() : '',
    // start_time: moment(preStamp + formData.startTime).isValid() ?  moment(preStamp + formData.startTime).format(): '',
    start_time: moment(preStamp + formData.startTime).format(),
    end_time: moment(preStamp + formData.endTime).format(),
    open_all_users: formData.eventType,
    local: formData.local,
    type_transmission: formData.typeTransmission,
    event_chat: formData.chat,
    event_quiz: formData.quiz,
    type_interactions: formData.typeInteractions,
    target_audience: formData.audience,
    publicProfile: formData.type_public,
    target_crop: formData.cultive,
    objective: formData.objective,
    // expected_result: formData.results,
    expected_num_users: formData.numUsers,
    schedule_basf: formData.schedule,
    observation: formData.observation
  }
}

export function cleanBeforeSave(formData) {
  const preStamp = '2022-01-01T';
  return {
    id: formData.id,
    id_user_company: 1,
    id_state: 4,
    name: formData.name,
    responsibleEmail: formData.responsibleEmail,
    requesterEmail: formData.requesterEmail,
    occupation_area: formData.occupationArea,
    date_event: moment(formData.date).isValid() ? moment(formData.date).format() : null,
    // start_time: moment(preStamp + formData.startTime).format(),
    // end_time: moment(preStamp + formData.endTime).format(),

    start_time: moment(preStamp + formData.startTime).isValid() ? moment(preStamp + formData.startTime).format() : null,
    end_time: moment(preStamp + formData.endTime).isValid() ? moment(preStamp + formData.endTime).format() : null,
    open_all_users: formData.eventType,
    local: formData.local,
    type_transmission: formData.typeTransmission,
    event_chat: formData.chat,
    event_quiz: formData.quiz,
    type_interactions: formData.typeInteractions,
    target_audience: formData.audience,
    type_public: formData.publicProfile,
    target_crop: formData.cultive,
    objective: formData.objective,
    // expected_result: formData.results,
    expected_num_users: formData.numUsers,
    schedule_basf: formData.schedule,
    observation: formData.observation
  }
}

export function populateFormRequest(data) {
  const keys = Object.keys(data);
  keys.forEach((key) => {
    if (data[key] === null) data[key] = '';
    // if (key !== 'date_event' && key !== 'start_time' && key !== 'end_time') {

    //   if (data[key] === null) data[key] = '';
    // }
  });

  return {
    id: data.id,
    name: data.name,
    responsibleEmail: data.responsibleEmail,
    requesterEmail: data.requesterEmail,
    occupationArea: data.occupation_area,
    local: data.local,
    date: data.date_event,
    startTime: data.start_time ? moment(data.start_time).format('HH:mm') : '',
    endTime: data.end_time ? moment(data.end_time).format('HH:mm') : '',
    eventType: data.open_all_users.toString(),
    typeTransmission: data.typeTransmissions,
    chat: data.event_chat,
    quiz: data.event_quiz,
    typeInteractions: data.type_interactions,
    audience: data.target_audience,
    publicProfile: data.type_public,
    cultive: data.target_crop,
    objective: data.objective,
    // results: data.expected_result,
    numUsers: data.expected_num_users,
    schedule: data.schedule_basf,
    observation: data.observation
  }
}


