import React from 'react';
import './reset.css';
import './global.css';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import PrivateRoute from './PrivateRoute';
import theme from './theme'
import LoginPage from "./pages/LoginPage";
import ForgetPassword from './pages/ForgetPassword';
import Main from './pages/Main';
import ChangePassword from './pages/ChangePassword';
import Loading from './components/Loading';
import PDFViewer from './components/PDFViewer';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Loading />
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/*" element={<Main />} />
            <Route path="/prices" element={<PDFViewer />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ChangePassword />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
