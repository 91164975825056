export const REQUEST_MODAL = 'REQUEST_MODAL';
export const CONFIRM_SEND_MODAL = 'CONFIRM_SEND_MODAL';
export const EXTENDED_CONFIRM_SEND_MODAL = 'EXTENDED_CONFIRM_SEND_MODAL';
export const SOLICITATION_SENDED_MODAL = 'SOLICITATION_SENDED_MODAL';
export const SAVED_MODAL = 'SAVED_MODAL';
export const CANCEL_MODAL = 'CANCEL_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const FREEZE_REQUEST = 'FREEZE_REQUEST';
export const OVERVIEW_MODAL = 'OVERVIEW_MODAL';
export const USER_FORM_MODAL = 'USER_FORM_MODAL';
export const APPROVE_MODAL = 'APPROVE_MODAL';
export const REPROVE_MODAL = 'REPROVE_MODAL';
export const DELETE_USER_MODAL = 'DELETE_USER_MODAL';
export const SCHEDULE_MODAL = 'SCHEDULE_MODAL';
export const EMAIL_MODAL = 'EMAIL_MODAL';
export const SUPPORT_MODAL = 'SUPPORT_MODAL';
export const RESET_MODAL = 'RESET_MODAL';
export const LOADING = 'LOADING';

export const deleteUser = (payload) => ({
  type: DELETE_USER_MODAL,
  payload,
});
export const userFormModal = (payload) => ({
  type: USER_FORM_MODAL,
  payload
});
export const requestModalAction = (payload) => ({
  type: REQUEST_MODAL,
  payload
});
export const confirmSendModalAction = (payload) => ({
  type: CONFIRM_SEND_MODAL,
  payload,
});
export const extendedConfirmSendModalAction = (payload) => ({
  type: EXTENDED_CONFIRM_SEND_MODAL,
  payload,
});
export const solicitationSendedModalAction = (payload) => ({
  type: SOLICITATION_SENDED_MODAL,
  payload,
});
export const savedModalAction = (payload) => ({
  type: SAVED_MODAL,
  payload,
});
export const cancelModalAction = (payload) => ({
  type: CANCEL_MODAL,
  payload,
});
export const removeModalAction = (payload) => ({
  type: REMOVE_MODAL,
  payload,
})
export const freezeRequestAction = (payload) => ({
  type: FREEZE_REQUEST,
  payload,
})
export const overviewAction = (payload) => ({
  type: OVERVIEW_MODAL,
  payload,
})
export const approveAction = (payload) => ({
  type: APPROVE_MODAL,
  payload,
})
export const reproveAction = (payload) => ({
  type: REPROVE_MODAL,
  payload,
})
export const schduleAction = (payload) => ({
  type: SCHEDULE_MODAL,
  payload,
})
export const emailAction = (payload) => ({
  type: EMAIL_MODAL,
  payload,
})
export const supportAction = (payload) => ({
  type: SUPPORT_MODAL,
  payload,
})
export const resetAction = (payload) => ({
  type:RESET_MODAL,
  payload,
})
export const loadingAction = (payload) => ({
  type: LOADING,
  payload,
})
