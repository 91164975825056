import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmSendModalAction, requestModalAction, savedModalAction, cancelModalAction, extendedConfirmSendModalAction } from '../redux/actions/modalsActions';
import { addDataAction } from '../redux/actions/requestActions';
import { DatePicker, LocalizationProvider } from '@mui/lab'
import useMediaQuery from '@mui/material/useMediaQuery';
import AdapterDateFns from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale'
import theme from '../theme';
import { colors } from '../styles';
import { cleanBeforeSave } from '../utils/eventFormUtils'
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Chip,
} from "@mui/material";
import moment from 'moment';
import axiosBasf from '../axiosConfig';
import axios from 'axios';
import validator from 'validator';
import { Link } from 'react-router-dom';
import CustomTimePicker from './CustomTimePicker';
import { set } from 'date-fns';

const mockAudience = [
  { id: 1, audience: 'Agricultor' },
  { id: 2, audience: 'Revenda' },
  { id: 3, audience: 'Cooperativa' },
  { id: 4, audience: 'Sementeiro' },
  { id: 5, audience: 'Influenciadores' },
  { id: 6, audience: 'Interno' },
]

const mockObjective = [
  { id: 1, objective: 'Relacionamento' },
  { id: 2, objective: 'Conteúdo' },
  { id: 3, objective: 'Negócios' },
  { id: 4, objective: 'Técnico' },
]
const responsibleAreaMock = [
  { id: 1, area: 'Acesso' },
  { id: 2, area: 'Comunicação' },
  { id: 3, area: 'Desenvolvimento Técnico de Mercado' },
  { id: 4, area: 'Marketing de Cultivos' },
  { id: 5, area: 'Outros' },
]

export default function EventForm({ freezeRequest }) {
  const [typeTransmissionList, setTypeTransmissionList] = useState([])
  const [typeInteractionList, setTypeInteractionList] = useState([])
  const [openLocalsList, setOpenLocalsList] = useState([])
  const [typeFarmsList, setTypeFarmsList] = useState([])
  const [closedLocalsList, setClosedLocalsList] = useState([])
  const [invalidFields, setInvalidFields] = useState([])
  const [audienceList, setAudienceList] = useState(mockAudience)
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const formHeader = useRef(null);

  const dispatch = useDispatch();
  const requestModalStatus = useSelector((reducer) => reducer.modalsReducer.requestIsOpen);
  const formData = useSelector((reducer) => reducer.requestReducer);

  useEffect(() => {
    axiosBasf('/type_transmission/list', { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        setTypeTransmissionList(response.data)
      })
      .catch((err) => console.log(err.request))
  }, [])

  useEffect(() => {
    axiosBasf('/type_interactions/list', { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        setTypeInteractionList(response.data)
      })
      .catch((err) => console.log(err.request))
  }, [])

  useEffect(() => {
    axiosBasf('/type_farmings/list', { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        setTypeFarmsList(response.data)
      })
      .catch((err) => console.log(err.request))
  }, [])

  useEffect(() => {
    axios.get('https://fazenda-agro.basf.com/api/rooms')
      .then(({ data }) => {
        setOpenLocalsList(data.filter(({ open_to_all_users }) => open_to_all_users === 1));
        setClosedLocalsList(data.filter(({ open_to_all_users }) => open_to_all_users === 0));
      })
  }, []);



  const handleClose = () => {
    setInvalidFields([])
    dispatch(requestModalAction(false));
  };

  const handleTypeTransmission = ({ target }) => {
    const value = typeof target.value === 'string' ? target.value.split(',') : target.value
    dispatch(addDataAction({ field: 'typeTransmission', data: value }));
  };

  const handletypeInteractionList = ({ target }) => {
    const value = typeof target.value === 'string' ? target.value.split(',') : target.value
    dispatch(addDataAction({ field: 'typeInteractions', data: value }));
  };


  const renderSelectedTransmissionHelper = (array) => {
    let result = [];
    array.forEach((value) => {
      const index = typeTransmissionList.findIndex(({ id }) => id === value);
      result.push(typeTransmissionList[index].description);
    });
    return result.join(', ');
  }

  const handleEventType = ({ target }) => {
    dispatch(addDataAction({ field: 'eventType', data: target.value }));
    if (target.value === '1') return dispatch(addDataAction({ field: 'local', data: openLocalsList[0].name }));
    dispatch(addDataAction({ field: 'local', data: closedLocalsList[0].name }));
  }

  const verifyStartEndTime = (invalidFields) => {
    const { startTime, endTime} = formData;
    const preStamp = '2022-01-01T';
    if (!moment(preStamp + startTime).isValid()) invalidFields.push('startTime');
    if (!moment(preStamp + endTime).isValid()) invalidFields.push('endTime');
    if (invalidFields.includes('startTime') || invalidFields.includes('endTime')) return;
    if (moment(preStamp + startTime).isAfter(moment(preStamp + endTime))) {
      invalidFields.push('startTime');
      invalidFields.push('endTime');
    }
  }

  const getInvalidFields = () => {
    setInvalidFields([]);
    const fields = []
    if (!formData.name.trim().length) fields.push('name');
    if (!formData.responsibleEmail.trim().length) fields.push('responsibleEmail');
    if (!validator.isEmail(formData.requesterEmail)) fields.push('requesterEmail');
    if (!formData.occupationArea) fields.push('occupationArea');
    if (!moment(formData.date).isValid()) fields.push('date');
    if (moment(formData.date) - moment(moment().format('YYYY-MM-DD')) < 0) fields.push('date');
    verifyStartEndTime(fields);
    // if (!moment(formData.startTime).isValid()) fields.push('startTime');
    // if (!moment(formData.endTime).isValid()) fields.push('endTime');
    if (!formData.local.trim().length) fields.push('local');
    if (!formData.typeTransmission.length) fields.push('typeTransmission');
    // if (!formData.typeInteractions.length) return false;
    if (!formData.audience.trim().length) fields.push('audience');
    if (!formData.publicProfile.trim().length) fields.push('publicProfile');
    if (!formData.cultive.trim().length) fields.push('cultive');
    if (!formData.objective.trim().length) fields.push('objective');
    if (!formData.numUsers || !formData.numUsers < 0) fields.push('numUsers');
    setInvalidFields(fields);
    formHeader.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    return fields;
  }
  // const validateFields = () => {
  //   if (!formData.name.trim().length) setInvalidField((prevState) => [...prevState, 'name']);
  //   if (!formData.responsibleEmail.trim().length) return false;
  //   if (!validator.isEmail(formData.requesterEmail)) return false;
  //   if (!formData.occupationArea) return false;
  //   if (!moment(formData.date).isValid()) return false;
  //   if (moment(formData.date) - moment(moment().format('YYYY-MM-DD')) < 0) return false;
  //   if (!moment(formData.startTime).isValid()) return false;
  //   if (!moment(formData.endTime).isValid()) return false;
  //   if (!formData.local.trim().length) return false;
  //   if (!formData.typeTransmission.length) return false;
  //   // if (!formData.typeInteractions.length) return false;
  //   if (!formData.audience.trim().length) return false;
  //   if (!formData.publicProfile.trim().length) return false;
  //   if (!formData.cultive.trim().length) return false;
  //   if (!formData.objective.trim().length) return false;
  //   if (!formData.numUsers || !formData.numUsers < 0) return false;
  //   return true;
  // }

  const handleSend = () => {
    if (getInvalidFields().length) return;
    // dispatch(requestModalAction(false))
    handleClose();
    dispatch(confirmSendModalAction(true))
  }
  const handleExtendedSend = () => {
    if (getInvalidFields().length) return;
    // dispatch(requestModalAction(false))
    handleClose();
    dispatch(extendedConfirmSendModalAction(true))
  }

  const handleSave = () => {
    axiosBasf.post('/events/save', cleanBeforeSave(formData), { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        handleClose();
        dispatch(savedModalAction(true));
      })
      .catch((err) => console.log('ERR', err))
  }

  const handleCancel = () => {
    dispatch(requestModalAction(false));
    dispatch(cancelModalAction(true));
  }

  const renderSelectedInteractionHelper = (array) => {
    let result = [];
    array.forEach((value) => {
      const index = typeTransmissionList.findIndex(({ id }) => id === value);
      result.push(typeInteractionList[index].name);
    });
    return result.join(', ');
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={requestModalStatus}
      onClose={handleClose}
      scroll="body"
    >
      <form ref={formHeader}>
        <Box >
          <DialogTitle color={colors.green}> {"Cadastrar solicitação"} </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TextField
              disabled={freezeRequest}
              label="Nome do evento *"
              name="name"
              value={formData.name}
              onChange={(({ target }) => dispatch(addDataAction({ field: 'name', data: target.value })))}
              fullWidth
              sx={{ mt: 1 }}
              error={invalidFields.includes('name')}
            />
            <TextField
              disabled={freezeRequest}
              label="E-mail do responsável pelo evento *"
              name="responsibleEmail"
              value={formData.responsibleEmail}
              onChange={(({ target }) => dispatch(addDataAction({ field: 'responsibleEmail', data: target.value })))}
              helperText="O responsável é quem realiza o evento."
              fullWidth
              error={invalidFields.includes('responsibleEmail')}

            />
            <TextField
              disabled={freezeRequest}
              label="E-mail do Solicitante *"
              name="requesterEmail"
              value={formData.requesterEmail}
              onChange={(({ target }) => dispatch(addDataAction({ field: 'requesterEmail', data: target.value })))}
              helperText="O solicitante é quem demanda o evento."
              fullWidth
              error={invalidFields.includes('requesterEmail')}

            />
            <FormControl>
              <InputLabel id="responsible-area" error={invalidFields.includes('occupationArea')} >Área do responsável pelo evento *</InputLabel>
              <Select
                label="Área do responsável pelo evento *"
                labelId="responsible-area"
                name="responsibleArea"
                value={formData.occupationArea}
                onChange={({ target }) => dispatch(addDataAction({ field: 'occupationArea', data: target.value }))}
                error={invalidFields.includes('occupationArea')}
              >
                {responsibleAreaMock.map(({ id, area }) => (
                  <MenuItem key={id} value={area}>{area}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={freezeRequest}
                label="Data do evento *"
                value={formData.date}
                minDate={moment().valueOf()}
                onChange={(newValue) => dispatch(addDataAction({ field: 'date', data: newValue }))}
                renderInput={(params) => <TextField {...params} error={invalidFields.includes('date')}/>}
                inputProps={{ readOnly: true }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: '30px' }}>
                <Box sx={{ width: '100%' }}>
                  <CustomTimePicker label="Horário de início" fieldName="startTime" value={formData.startTime} error={invalidFields.includes('startTime')} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <CustomTimePicker label="Horário de término" fieldName="endTime" value={formData.endTime} error={invalidFields.includes('endTime')}/>
                </Box>
              </Box>
            </LocalizationProvider>
            <Divider light />
            <FormLabel id="event-type">Tipo do evento *</FormLabel>
            <RadioGroup
              name="event-type"
              defaultValue={formData.eventType}
              value={formData.eventType}
              row
              onChange={handleEventType}
              aria-labelledby="event-type"
            >
              <FormControlLabel disabled={freezeRequest} value="1" control={<Radio />} selected label="Aberto para todos os usuários" />
              <FormControlLabel disabled={freezeRequest} value="0" control={<Radio />} label="Fechado, acesso apenas para usuários específicos" />
            </RadioGroup>
            <Divider light />
            <FormControl>
              <InputLabel id="event-local" error={invalidFields.includes('local')}>Onde será realizado? *</InputLabel>
              <Select
                error={invalidFields.includes('local')}
                label="Onde será realizado? *"
                labelId="event-local"
                name="local"
                // value={formData.eventType === '1' ? openLocalsList[0] : closedLocalsList[0]}
                value={formData.local}
                onChange={({ target }) => dispatch(addDataAction({ field: 'local', data: target.value }))}
              >
                {(formData.eventType === '1' ? openLocalsList : closedLocalsList).map(({ id, name }) => (
                  <MenuItem key={id} value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="type_transmissions" error={invalidFields.includes('typeTransmission')}>Tipos de transmissão*</InputLabel>
              <Select
                error={invalidFields.includes('typeTransmission')}
                labelId="type_transmissions"
                label="Tipos de transmissão"
                multiple
                value={formData.typeTransmission}
                onChange={handleTypeTransmission}
                renderValue={(selected) => renderSelectedTransmissionHelper(selected)}
              >
                {typeTransmissionList.map(({ id, description }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={formData.typeTransmission.indexOf(id) > -1} />
                    <ListItemText primary={description} />
                  </MenuItem>
                ))}
              </Select>
              <Link to="/prices" target="_blank" style={{ color: '#008247', fontSize: '14px', margin: '14px' }}>Clique aqui para acessar tabela de valores</Link>
            </FormControl>

            <FormControl>
              <InputLabel id="type-interactions">Interações</InputLabel>
              <Select
                label="Interações*"
                labelId="type-interactions"
                multiple
                value={formData.typeInteractions}
                onChange={handletypeInteractionList}
                renderValue={(selected) => renderSelectedInteractionHelper(selected)}
              >
                {typeInteractionList.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={formData.typeInteractions.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider light />
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Box>
                <FormLabel id="demo-row-radio-buttons-group-label">O evento terá quiz? *</FormLabel>
                <RadioGroup
                  row
                  value={formData.quiz}
                  name="have-quiz"
                  defaultValue="1"
                  onChange={({ target }) => dispatch(addDataAction({ field: 'quiz', data: target.value }))}
                  aria-labelledby="Evento terá quiz? *"
                >
                  <FormControlLabel value="1" control={<Radio />} selected label="Sim" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                </RadioGroup>
              </Box>
              <Box>
                <FormLabel id="demo-row-radio-buttons-group-label">O evento terá chat? *</FormLabel>
                <RadioGroup
                  row
                  value={formData.chat}
                  name="have-chat"
                  defaultValue="1"
                  onChange={({ target }) => dispatch(addDataAction({ field: 'chat', data: target.value }))}
                  aria-labelledby="Evento terá chat? *"
                >
                  <FormControlLabel value="1" control={<Radio />} selected label="Sim" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                </RadioGroup>
              </Box>
            </Box>
            <FormControl>
              <InputLabel id="target-audience" error={invalidFields.includes('audience')}>Qual o público?</InputLabel>
              <Select
                error={invalidFields.includes('audience')}
                labelId='target-audience'
                name="target-audience"
                value={formData.audience}
                label="Qual o público?"
                onChange={({ target }) => dispatch(addDataAction({ field: 'audience', data: target.value }))}
              >
                {audienceList.map(({ id, audience }) => (
                  <MenuItem key={id + audience} value={audience}>{audience}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              error={invalidFields.includes('publicProfile')}
              name="public-profile"
              label="Qual o perfil do público? *"
              value={formData.publicProfile}
              onChange={({ target }) => dispatch(addDataAction({ field: 'publicProfile', data: target.value }))}
              fullWidth
              sx={{ mt: 1 }}
            />
            <FormControl>
              <InputLabel id="cultive" error={invalidFields.includes('cultive')}>Cultivo *</InputLabel>
              <Select
                error={invalidFields.includes('cultive')}
                labelId='cultive'
                name="cultive"
                value={formData.cultive}
                label="Cultivo *"
                onChange={({ target }) => dispatch(addDataAction({ field: 'cultive', data: target.value }))}
              >
                {typeFarmsList.map(({ id, name }) => (
                  <MenuItem key={id + name} value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="objective" error={invalidFields.includes('objective')}>Qual o objetivo? *</InputLabel>
              <Select
                error={invalidFields.includes('objective')}
                labelId='objective'
                name="objective"
                value={formData.objective}
                label="QUal o objetivo *"
                onChange={({ target }) => dispatch(addDataAction({ field: 'objective', data: target.value }))}
              >
                {mockObjective.map(({ id, objective }) => (
                  <MenuItem key={id + objective} value={objective}>{objective}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              name="objective"
              label="Qual o objetivo? *"
              value={formData.objective} onChange={({ target }) => dispatch(addDataAction({ field: 'objective', data: target.value }))}
              fullWidth
            /> */}
            {/* <TextField
              name="results"
              label="Quais os resultados esperados? *"
              value={formData.results}
              onChange={({ target }) => dispatch(addDataAction({ field: 'results', data: target.value }))}
              fullWidth
              sx={{ mt: 1 }}
            /> */}
            <TextField
              error={invalidFields.includes('numUsers')}
              name="participants"
              type="number"
              label="Quantidade de participantes estimados *"
              value={formData.numUsers}
              onChange={({ target }) => dispatch(addDataAction({ field: 'numUsers', data: target.value }))}
              fullWidth
            />
            <Divider light />
            <FormLabel id="demo-row-radio-buttons-group-label">Publicar na agenda da Fazenda BASF? *</FormLabel>
            <RadioGroup
              row
              defaultValue="0"
              onChange={({ target }) => dispatch(addDataAction({ field: 'schedule', data: target.value }))}
              value={formData.schedule}
            >
              <FormControlLabel value="1" control={<Radio />} selected label="Sim" />
              <FormControlLabel value="0" control={<Radio />} label="Não" />
            </RadioGroup>
            <TextField
              id="outlined-multiline-flexible"
              label="Observação"
              multiline
              rows={3}
              value={formData.observation}
              onChange={({ target }) => dispatch(addDataAction({ field: 'observation', data: target.value }))}
            />
          </DialogContent>
          <DialogActions sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <Button variant="contained" onClick={handleSend}>
                  Enviar
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSave}>
                  Salvar rascunho
                </Button>
                <Button variant="contained" color="error" onClick={handleCancel}>
                  Cancelar
                </Button>
              </Box>
              <Box sx={{ marginBottom: '20px' }}>
                <Button variant="contained" onClick={handleExtendedSend}>
                  Estender evento para mais de um dia
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  )
}