import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { deleteUser } from '../../redux/actions/modalsActions';
import axiosBasf from '../../axiosConfig';
import { Divider } from '@mui/material';
import { fetchUsersAction } from '../../redux/actions/users';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RemoveUser(props) {

  const deleteUserOpenModal = useSelector((reducer) => reducer.modalsReducer.deleteUserOpen);
  const dispatch = useDispatch();
  const handleNoClick = () => {
    dispatch(deleteUser(false));
  }

  const handleRemoveClick = () => {
    axiosBasf.delete(`/user/delete/${props.idUser}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(deleteUser(false));
        props.toast.error('Usuário deletado com sucesso')
        dispatch(fetchUsersAction())
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={deleteUserOpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNoClick} //backclick
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error" size="large" sx={{ textAlign: 'center', fontSize: '25px' }}>Atenção!</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center' }}>
            Tem certeza que deseja remover este usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button onClick={handleRemoveClick} variant="contained">Sim, remover</Button>
          <Button onClick={handleNoClick} variant="contained" color="error">Não</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}