import { Routes, Route, Navigate } from 'react-router-dom';
import Box from "@mui/system/Box";
import CalendarHolder from "../components/CalendarHolder";
import Solicitations from './Solicitations';
import Users from './Users';
import Events from './Events';
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import EventForm from "../components/EventForm";
import ConfirmSend from '../components/modals/ConfirmSend';
import { styled } from '@mui/system';
import { colors } from '../styles';
import SolicitationSended from '../components/modals/SolicitationSended';
import Saved from '../components/modals/Saved';
import Cancel from '../components/modals/Cancel';
import Profile from './Profile';
import Support from '../components/modals/Support';
import Dashboard from './DashBoard';
import ConfirmExtendedSend from '../components/modals/ConfirmExtendedSend';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { tipEnableChromeAction, tipToggleChromeAction } from '../redux/actions/tipAction';
import { useEffect } from 'react';


const ScrollableBox = styled(Box)({
  height: '100vh',
  width: '100%',
  overflowY: 'scroll',
  backgroundColor: colors.gray,
  display: 'flex',
  flexDirection: 'column',
  aligItems: 'center',
  padding: '0 15px 15px 15px',
})

export default function Main() {
  const dispatch = useDispatch();
  const tipChrome = useSelector((reducer) => reducer.tipReducer.tipChrome);

  const toggleTip = () => {
    dispatch(tipToggleChromeAction())
  }
  
  useEffect(() => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (!isChrome) dispatch(tipEnableChromeAction())
  })

  return (
    <Box sx={{ width: '100%', height: '100vh'}}>
      {tipChrome && <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'yellow',
          width: '100%',
        }}
      >
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          Para uma melhor experiência de navegação, recomendamos a utilização do navegador <b>Google Chrome</b>.
        </Typography>
        <Button color="dark" onClick={toggleTip}>X</Button>
      </Box>}

      <Box sx={{ display: '-webkit-box', maxHeight: '100vh' }}>
        <Box sx={{ minHeight: '100vh' }}>
          <SideBar />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <EventForm />
          <ScrollableBox>
            <Header />
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="home" element={<CalendarHolder />} />
              <Route path="solicitations" element={<Solicitations />} />
              <Route path="users" element={<Users />} />
              <Route path="events" element={<Events />} />
              <Route path="profile" element={<Profile />} />
              <Route path="dashboard/:id" element={<Dashboard />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </ScrollableBox>
          <ConfirmSend />
          <SolicitationSended />
          <ConfirmExtendedSend />
          <Saved />
          <Cancel />
          <Support />
        </Box>
      </Box>
    </Box>
  )
}