import { GET_USERS } from '../actions/users';


const INITIAL_STATE = { users: [] };

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, users: payload };
    default:
      return state;
  }
}

export default userReducer;