import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

export default function NotFound () {
  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography sx={{ fontSize: '120px' }} color="primary">404!</Typography>
      <Typography sx={{ fontSize: '20px'}}>
        Para voltar para o site
        <Link to="/home" style={{ color: '#008247'}}> clique aqui.</Link>
      </Typography>
      <HeartBrokenIcon sx={{ fontSize: '50px', color: '#4c4c4c', marginTop: '20px' }}/>
    </Box>
  )
}