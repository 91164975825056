import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, TextField, Typography, ThemeProvider, Box, Slide, Divider } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import { colors } from '../styles'
import validator from 'validator'
import 'react-toastify/dist/ReactToastify.css';
import EmailSended from '../components/modals/EmailSended';
import { emailAction, loadingAction } from '../redux/actions/modalsActions';
import axiosBasf from '../axiosConfig';



export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: { status: false, message: '' } })

  useEffect(() => {
    if (!email.trim().length) return setButtonDisabled(true);
    if (!validator.isEmail(email)) return setButtonDisabled(true);
    return setButtonDisabled(false);
  }, [email]);

  const handleSend = () => {
    dispatch(loadingAction(true))
    axiosBasf.post(`/recovery-password/`, { email }, { headers: { authorization: localStorage.getItem('token') } })
    .then(result => {
      dispatch(loadingAction(false))
      dispatch(emailAction(true))
    })
    .catch((_err) =>{
        dispatch(loadingAction(false))
        toast.error('Algo deu errado! Tente novamente')
      })
  }

  const handleBack = () => {
    navigate('/login')
  }

  const handleEmail = ({ target }) => {
    setErrors((errors) => ({ ...errors, email: { status: false, message: '' } }));
    const { value } = target
    setEmail(value);
    if (!validator.isEmail(value)) return setErrors((errors) => ({ ...errors, email: { status: true, message: 'Insira um e-mail válido.' } }))
    setErrors((errors) => ({ ...errors, email: { status: false, message: '' } }));
  }

  const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
  }

  const handleKeyPress = ({ key }) => {
    if (key === 'Enter' && buttonDisabled === false) handleSend();
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 2, backgroundColor: colors.green, backgroundImage: 'url(assets/img/login-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Box sx={{ maxWidth: '150px' }}>
          <Typography variant="h5" size="large" sx={{ color: 'white', fontWeight: '800' }}>Plataforma Administrativa de Eventos</Typography>
        </Box>
      </Box>
      <Box sx={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexGrow: 4, backgroundColor: colors.gray }}>
        <Box sx={{ width: '100%' }}>
          <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '30px', margin: 'auto', maxWidth: "400px" }}>
            <Typography variant="title-primary" align="center">Esqueceu sua senha?</Typography>
            <Typography variant="h8" align="center" color={colors.black}>Digite seu endereço de e-mail. Você receberá um link para cadastrar uma nova senha.</Typography>
            <TextField
              error={errors.email.status}
              size="small"
              helperText={errors.email.message}
              label="E-mail"
              onChange={handleEmail}
              value={email}
              onKeyPress={handleKeyPress}
            />
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Button fullWidth disabled={buttonDisabled} variant="contained" onClick={handleSend}>Enviar</Button>
              <Button fullWidth color="error" variant="contained" onClick={handleBack}>Voltar</Button>
            </Box>
          </Paper>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <EmailSended />
        </Box>
      </Box>
    </Box>
  )
}