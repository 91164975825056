import { forwardRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { reproveAction } from '../../redux/actions/modalsActions';
import { fetchSolicitationsAction } from '../../redux/actions/eventsActions';
import axiosBasf from '../../axiosConfig';
import { Divider, TextField } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Reprove({ idTarget}) {

  const [justification, setJustification] = useState('');
  const reproveIsOpen = useSelector((reducer) => reducer.modalsReducer.reproveIsOpen);
  const solicitations = useSelector((reducer) => reducer.eventsReducer.solicitations);
  const dispatch = useDispatch();

  const handleNoClick = () => {
    dispatch(reproveAction(false));
  }
  
  const handleReproveClick = () => {
    axiosBasf.post(`events/approve/${idTarget}`, {id_state: 3, desapproved_justification: justification}, { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(reproveAction(false));
        dispatch(fetchSolicitationsAction())
      })
      .catch((err) => console.log(err.request))
  }

  useEffect(() => {
    setJustification('');
  }, [idTarget, solicitations])

  return (
    <div>
      <Dialog
        open={reproveIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNoClick} //backclick
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error" size="large" sx={{ textAlign: 'center', fontSize: '25px'}}>Reprovar solicitação</DialogTitle>
        <Divider />
        <DialogContent>
          {/* <DialogContentText color="error" id="alert-dialog-slide-description" sx={{ textAlign: 'center'}}>
            Digite abaixo o motivo da reprovação.
          </DialogContentText> */}
          <TextField
          sx={{ minWidth: '400px'}}
          placeholder="Digite o motivo da reprovação"
          multiline
          // variant="filled"
          // rows="3"
          // sx={{ textAlign: 'center'}}
          // variant="filled"
          value={justification}
          onChange={({target}) => setJustification(target.value)}
        />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button disabled={!justification.trim().length} onClick={ handleReproveClick } variant="contained">Reprovar</Button>
          <Button onClick={handleNoClick} variant="contained" color="error">Voltar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}