import {
  GET_SOLICITATIONS,
  GET_APPROVED_SOLICITATIONS,
} from "../actions/eventsActions";

const INITIAL_STATE = {solicitations: [], approvedSolicitations: []};

const eventsReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch(type) {
  case GET_SOLICITATIONS:
    return {...state, solicitations: payload};
  case GET_APPROVED_SOLICITATIONS:
    return {...state, approvedSolicitations: payload};
  default:
    return state;
  }
}

export default eventsReducer;