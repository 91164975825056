import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addDataAction } from '../redux/actions/requestActions';
import { Autocomplete, TextField } from '@mui/material';

export default function CustomTimePicker({ label, value, fieldName, error }) {
  const { startTime, endTime, date} = useSelector((reducer) => reducer.requestReducer)
  const dispatch = useDispatch();
  moment.locale('pt-br');


  // const timeList = [
  //   '00:00',
  //   '00:30',
  //   '01:00',
  //   '01:30',
  //   '02:00',
  //   '02:30',
  //   '03:00',
  //   '03:30',
  //   '04:00',
  //   '04:30',
  //   '05:00',
  //   '05:30',
  //   '06:00',
  //   '06:30',
  //   '07:00',
  //   '07:30',
  //   '08:00',
  //   '08:30',
  //   '09:00',
  //   '09:30',
  //   '10:00',
  //   '10:30',
  //   '11:00',
  //   '11:30',
  //   '12:00',
  //   '12:30',
  //   '13:00',
  //   '13:30',
  //   '14:00',
  //   '14:30',
  //   '15:00',
  //   '15:30',
  //   '16:00',
  //   '16:30',
  //   '17:00',
  //   '17:30',
  //   '18:00',
  //   '18:30',
  //   '19:00',
  //   '19:30',
  //   '20:00',
  //   '20:30',
  //   '21:00',
  //   '21:30',
  //   '22:00',
  //   '22:30',
  //   '23:00',
  //   '23:30',
  // ]

  // const calculateDiffTime = (eventTime) => {
  //   const eventDate = moment(date).format('YYYY-MM-DD');
  //   const eventDateTime = moment(eventDate + 'T' + eventTime);
  //   const duration = moment.duration(eventDateTime.diff(moment()));
  //   return `(${duration.hours()}:${duration.minutes()})`;
  // }

  const timeList = [
    {label: '00:00', value: '00:00'},
    {label: '00:30', value: '00:30'},
    {label: '01:00', value: '01:00'},
    {label: '01:30', value: '01:30'},
    {label: '02:00', value: '02:00'},
    {label: '02:30', value: '02:30'},
    {label: '03:00', value: '03:00'},
    {label: '03:30', value: '03:30'},
    {label: '04:00', value: '04:00'},
    {label: '04:30', value: '04:30'},
    {label: '05:00', value: '05:00'},
    {label: '05:30', value: '05:30'},
    {label: '06:00', value: '06:00'},
    {label: '06:30', value: '06:30'},
    {label: '07:00', value: '07:00'},
    {label: '07:30', value: '07:30'},
    {label: '08:00', value: '08:00'},
    {label: '08:30', value: '08:30'},
    {label: '09:00', value: '09:00'},
    {label: '09:30', value: '09:30'},
    {label: '10:00', value: '10:00'},
    {label: '10:30', value: '10:30'},
    {label: '11:00', value: '11:00'},
    {label: '11:30', value: '11:30'},
    {label: '12:00', value: '12:00'},
    {label: '12:30', value: '12:30'},
    {label: '13:00', value: '13:00'},
    {label: '13:30', value: '13:30'},
    {label: '14:00', value: '14:00'},
    {label: '14:30', value: '14:30'},
    {label: '15:00', value: '15:00'},
    {label: '15:30', value: '15:30'},
    {label: '16:00', value: '16:00'},
    {label: '16:30', value: '16:30'},
    {label: '17:00', value: '17:00'},
    {label: '17:30', value: '17:30'},
    {label: '18:00', value: '18:00'},
    {label: '18:30', value: '18:30'},
    {label: '19:00', value: '19:00'},
    {label: '19:30', value: '19:30'},
    {label: '20:00', value: '20:00'},
    {label: '20:30', value: '20:30'},
    {label: '21:00', value: '21:00'},
    {label: '21:30', value: '21:30'},
    {label: '22:00', value: '22:00'},
    {label: '22:30', value: '22:30'},
    {label: '23:00', value: '23:00'},
    {label: '23:30', value: '23:30'},
  ]
  
  const handleChange = (e, val) => {
    let time = val.value;
    if (!time && !e) return;
    if (!time) time = e.target.value;
    dispatch(addDataAction({ field: fieldName, data: time }));
  }
  
  const verifyTime = ({ target }) => {
    const time = `2022-01-01T${target.value}`
    if (!target) return;
    if (target.value.length !== 5 || !moment(time).isValid()) {
      dispatch(addDataAction({ field: fieldName, data: '' }));
      return;
    }
  }

  return (
    <Box>
      <FormControl fullWidth>
      <Autocomplete        
        id="free-solo-demo"
        freeSolo
        options={timeList.map((time) => time)}
        onInputChange={(e, val) => handleChange(e, val)}
        onChange={(e, val) => handleChange(e, val)}
        inputValue={value}
        disableClearable
        renderInput={(params) => <TextField {...params} label={label} onBlur={verifyTime} error={error}/>}
      />
      </FormControl>
    </Box >
  );
}




/*
import React, {useState} from 'react;
import InputMask from "react-input-mask";

export default function MaskedInput() {
  const [actualTime, setActualTime] = useState('');

  const handleChange = ({ target }) => {
      setActualTime(target.value);
    };

  return (
    <InputMask mask="99:99" value={value} onChange={handleChange}>
      {
        inputProps => (
          <TextField
            label={label}
            variant="outlined"
            type="text"
            fullWidth
            required
            {...inputProps}
          />
        )
      }
    </InputMask>
  )

}
*/