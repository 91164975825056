import * as React from 'react';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, Toolbar, Button } from '@mui/material'
import { userFormModal, deleteUser } from '../../redux/actions/modalsActions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { populateDataAction, clearDataAction } from '../../redux/actions/userModalActions';
import { populateFormUser } from '../../utils/userFormUtils';
import axiosBasf from '../../axiosConfig';
import RemoveUser from '../../components/modals/RemoveUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUsersAction } from '../../redux/actions/users';
import UserFormModal from '../../components/modals/UserFormModal';
import { emailAction } from '../../redux/actions/modalsActions';
import EmailSended from '../../components/modals/EmailSended';



export default function DenseTable() {
    const [idUser, setIdUser] = useState(null);
    const [mailError, setMailError] = useState(false);
    const [mailTextError, setMailTextError] = useState('');
    const [idTarget, setIdTarget] = useState(null);

    const userData = useSelector((reducer) => reducer.authReducer.user)
    const usersList = useSelector((reducer) => reducer.userReducer.users).filter(({ id }) => userData.id !== id);

    const dispatch = useDispatch();
    const handleOpenUserForm = (e) => {
        setMailError(false);
        setMailTextError('');
        dispatch(clearDataAction())
        dispatch(userFormModal(true));
    }

    useEffect(() => {
        dispatch(fetchUsersAction());
    }, [])

    async function handleEditButton(id) {
        setIdTarget(id);
        setMailError(false);
        setMailTextError('');
        dispatch(clearDataAction())
        const { data } = await axiosBasf.get(`/user/getUser/${id}`, { headers: { authorization: localStorage.getItem('token') } })
        const cleanedPopulatedData = populateFormUser(data);
        dispatch(populateDataAction(cleanedPopulatedData));
        dispatch(userFormModal(true));
    }
    async function handleSendMail(email) {
        await axiosBasf.post(`/recovery-password/`, { email }, { headers: { authorization: localStorage.getItem('token') } }).then(result => {
            dispatch(emailAction(true))
        })
    }

    async function handleDeleteButton(id) {
        setIdUser(id)
        dispatch(deleteUser(true));
    }

    function generateButtons(status, id, email) {
        const maxWidth = '200px'
        const minWidth = '120px'

        const seeSolicitationButton = <Button onClick={() => handleSendMail(email)} fullWidth size="small" color="primary" variant="contained">Reenviar senha</Button>;
        const activeScheduleBasfButton = <Button onClick={() => handleEditButton(id)} fullWidth size="small" color="primary" variant="contained" >Editar</Button>;
        const deleteUserButton = <Button onClick={() => handleDeleteButton(id)} fullWidth size="small" color="error" variant="contained" >Deletar</Button>;

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', }}>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth, marginRight: '20px' }}>
                    {seeSolicitationButton}
                </div>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth, minWidth, marginRight: '20px' }}>
                    {activeScheduleBasfButton}
                </div>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', maxWidth, minWidth }}>
                    {deleteUserButton}
                </div>
            </div>
        )
    }

    return (
        <Box>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },


                }}
            >

                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    color="primary"
                >
                    Lista de usuários
                </Typography>

                <Button variant="contained" onClick={handleOpenUserForm} style={{ width: '200px' }} startIcon={<AddCircleOutlineIcon />}>Novo usuário</Button>


            </Toolbar>

            <TableContainer>
                <Table sx={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width='100px'>Nome</TableCell>
                            <TableCell align="left" width='200px'>Email</TableCell>
                            <TableCell align="left" width='100px'>Permissão</TableCell>
                            <TableCell align="center" width='75px'>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersList.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                                <TableCell align="left" sx={{ minWidth: '200px' }}>{generateButtons(row.state, row.id, row.email)}</TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <UserFormModal setMailError={setMailError} mailError={mailError} mailTextError={mailTextError} setMailTextError={setMailTextError} idTarget={idTarget} />
            <RemoveUser idUser={idUser} toast={toast} />
            <EmailSended />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />

        </Box>
    );
}