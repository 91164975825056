import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { cancelModalAction, savedModalAction } from '../../redux/actions/modalsActions';
import axiosBasf from '../../axiosConfig';
import { generateBodyForm } from '../../utils/eventFormUtils'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const cancelIsOpen = useSelector((reducer) => reducer.modalsReducer.cancelIsOpen);
  const formData = useSelector((reducer) => reducer.requestReducer)
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(cancelModalAction(false));
  }
  
  const handleSaveClick = () => {
    dispatch(savedModalAction(true));
    axiosBasf.post('/events/save', generateBodyForm(formData, 4), { headers: { authorization: localStorage.getItem('token') } })
      .then((_response) => {
        dispatch(cancelModalAction(false));
        dispatch(savedModalAction(true));
      })
      .catch((err) => console.log(err.request))
  }

  return (
    <div>
      <Dialog
        open={cancelIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBackClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error" size="large" sx={{ textAlign: 'center', fontSize: '25px'}}>Atenção!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center'}}>
            Tem certeza que deseja da solicitação sem salva-la?.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3}}>
          <Button onClick={ handleSaveClick } variant="contained">Quero, salvar</Button>
          <Button onClick={ handleBackClick } variant="contained" color="error">Sim, sair</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}