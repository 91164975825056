import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { requestModalAction } from '../redux/actions/modalsActions';
import { logoutAction } from '../redux/actions/authActions';
import { clearDataAction } from '../redux/actions/requestActions';
import { Button, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';
import { monthPtBr, weekDayPtBr } from '../utils/dates';

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const userName = useSelector((reducer) => reducer.authReducer.user.name)

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleOpenEventForm = (e) => {
    dispatch(clearDataAction());
    dispatch(requestModalAction(true));
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(logoutAction());
    navigate('/login');
  }


  const getActualDate = () => {
    const month = monthPtBr(moment().utcOffset('-03:00').format('MMMM'));
    const weekDay = weekDayPtBr(moment().utcOffset('-03:00').day())
    const monthDay = moment().locale('pt').utcOffset('-03:00').format('DD')
    const year = moment().utcOffset('-03:00').format('YYYY')
    return `${weekDay}, ${monthDay} de ${month} de ${year}`;
  }

  return (
    <Box sx={{ backgroundColor: 'white', marginBottom: '20px'}}>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 0}}>
        <Typography>{ getActualDate() }</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justiyContent: 'center'}}>
          <Typography>Olá, <span style={{ color: '#008247'}}>{userName}</span></Typography>
          <IconButton onClick={handleOpenMenu}>
            <AccountCircle fontSize="large" color="primary"/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
          >
            <MenuItem><Link to="profile" style={{color: 'rgba(0, 0, 0, 0.87'}}>Perfil</Link></MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <Divider />
      <Toolbar sx={{ justifyContent: 'space-between'}}>
        <Typography variant="body-primary">Bem-vindo! | Criar uma solicitação de cada dia de evento</Typography>
        <Button data-testid="teste" variant="contained" onClick={handleOpenEventForm} startIcon={ <AddCircleOutlineIcon />}>Nova Solicitação</Button>
      </Toolbar>
      <Divider />
    </Box>
  )
}